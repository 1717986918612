import { useState, useEffect } from 'react'
import {
  Filter,
  FilterSidebar,
  FilterSidebarTreeChild,
  FilterSideBarVariant,
} from '@teamfabric/copilot-ui'
import {
  transformSelectedCheckboxOptions,
  resetCheckboxSelections,
} from './utils'

const GenericFilter = ({
  dataTestid,
  data,
  label,
  initialSelectedOptions,
  onChangeHandler = () => {},
  onResetHandler = () => {},
}: {
  dataTestid: string
  data: FilterSidebarTreeChild[]
  label: string
  initialSelectedOptions: number
  onChangeHandler?: ({ filterName, selectedOptions, checkboxOptions }) => void
  onResetHandler?: () => void
}) => {
  const [checkboxOptions, setCheckboxOptions] = useState<
    FilterSidebarTreeChild[]
  >([])
  const [numOfSelectedOptions, setNumOfSelectedOptions] = useState(0)

  const onFilterChange = (optionName: string) => {
    const {
      checkboxStateResult,
      numOfSelectedOptions: newNumOfSelectedOptions,
      selectedFilterOptions,
    } = transformSelectedCheckboxOptions(optionName, checkboxOptions)
    setCheckboxOptions(checkboxStateResult)
    setNumOfSelectedOptions(newNumOfSelectedOptions)
    onChangeHandler({
      filterName: label,
      selectedOptions: selectedFilterOptions,
      checkboxOptions: checkboxStateResult,
    })
  }

  const onFilterReset = () => {
    setCheckboxOptions(resetCheckboxSelections(checkboxOptions))
    setNumOfSelectedOptions(0)
    onResetHandler()
  }

  useEffect(() => {
    if (!data?.length) return
    setCheckboxOptions(data)
  }, [data])

  useEffect(() => {
    setNumOfSelectedOptions(initialSelectedOptions)
  }, [initialSelectedOptions])

  const renderFilterOptions = () => (
    <FilterSidebar
      isFlat
      maxHeight='300px'
      header=''
      onChange={onFilterChange}
      checkboxTree={checkboxOptions}
      variant={FilterSideBarVariant.SECONDARY}
      footer={
        numOfSelectedOptions > 0 && {
          label: '',
          linkText: 'Reset',
          onLinkClick: onFilterReset,
        }
      }
    />
  )
  return (
    <Filter
      {...(numOfSelectedOptions > 0 && {
        filterCount: numOfSelectedOptions.toString(),
      })}
      dataTestid={dataTestid}
      variant='primary'
      label={label}
      renderResults={renderFilterOptions()}
    />
  )
}

export default GenericFilter
