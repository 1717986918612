import { configureStore, combineReducers } from '@reduxjs/toolkit'
import invoicesSlice from './invoicesSlice'
import cancellationsSlice from './cancellationsSlice'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import contextSlice from './contextSlice'
import orderSlice from './orderSlice'
import productsSlice from './productsSlice'
import permitsSlice from './permitsSlice'
import userSlice from './userSlice'
import onboardingSlice from './onboardingSlice'
import proposalsSlice from './proposalsSlice'
import proposalProductsSlice from './proposalProductsSlice'
import proposalProductItemsSlice from './proposalProductItemsSlice'
import proposalItemsSlice from './proposalItemsSlice'
import merchantSlice from './merchantSlice'
import supplierSlice from './suppliersSlice'
import shipmentsSlice from './shipmentsSlice'
import returnsSlice from './returnsSlice'
import feedRequestSlice from './feedRequestSlice'
import inventorySummarySlice from './inventorySummarySlice'
import proposalDepartmentsSlice from './proposalDepartmentsSlice'
import productInventoryTemplatesSlice from './productInventoryTemplatesSlice'
import productRulesetsSlice from './productRulesetsSlice'

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const rootReducer = combineReducers({
  orders: orderSlice,
  invoices: invoicesSlice,
  cancellations: cancellationsSlice,
  user: userSlice,
  context: contextSlice,
  products: productsSlice,
  permits: permitsSlice,
  onboarding: onboardingSlice,
  merchant: merchantSlice,
  proposals: proposalsSlice,
  proposalProducts: proposalProductsSlice,
  proposalProductItems: proposalProductItemsSlice,
  proposalItems: proposalItemsSlice,
  suppliers: supplierSlice,
  shipments: shipmentsSlice,
  returns: returnsSlice,
  feedRequest: feedRequestSlice,
  inventorySummary: inventorySummarySlice,
  proposalDepartments: proposalDepartmentsSlice,
  productInventoryTemplates: productInventoryTemplatesSlice,
  productRulesets: productRulesetsSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
