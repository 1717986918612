import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import VariantItem from 'molecules/lineItem/variantItem'
import { Box, Table } from 'atoms'

type ChildProps = {
  data: any
  proposal: any
  isRetailer: boolean
  closeIssueModal?: Dispatch<SetStateAction<boolean>>
}

const IssueItemsTable: FC<ChildProps> = (props) => {
  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props.data])

  const tableColumns = [
    {
      title: 'Item',
      width: 200,
    },
    {
      title: 'Supplier SKU',
      width: 150,
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((item) => {
      return {
        id: item?.variant?.id,
        data: {
          'Item': {
            onRender: () =>
              VariantItem({
                variant: item?.variant,
                hideImage: true,
                showUPC: true,
                showVariantDetails: true,
              }),
          },
          'Supplier SKU': {
            value: item?.variant?.brand_identifier
              ? item.variant.brand_identifier
              : 'Not Set',
          },
        },
      }
    })
    setTableData(formattedData)
  }

  return (
    <>
      <Box width='100%' padding={{ top: 4, bottom: 4 }}>
        <Table columns={tableColumns} data={tableData} />
      </Box>
    </>
  )
}

export default IssueItemsTable
