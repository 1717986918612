import React from 'react'
import { Tag as TagUI } from '@teamfabric/copilot-ui'

type TagProps = {
  id: string
  primaryLabel: string
  isError?: boolean
  isRemovable?: boolean
  onRemove?: () => void
}

const Tag: React.FC<TagProps> = ({ ...props }) => {
  return <TagUI {...props} />
}

export default Tag
