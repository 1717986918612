import React, { useState } from 'react'
import Box from 'atoms/box/box'
import Button, { ButtonVariants } from 'atoms/button/button'
import { ICONS } from 'lib/constants'
import RemoveProposalProductModal from 'modules/proposals/components/modals/removeProposalProduct'

type BulkActionsProposalProductsProps = {
  loadProposal?: (quiet?: boolean) => void
  loadProducts?: (quiet?: boolean) => void
}

const BulkActionsProposalProducts: React.FC<
  BulkActionsProposalProductsProps
> = ({ loadProducts }) => {
  const [showRemoveProductsModal, setShowRemoveProductsModal] = useState(false)

  const RenderRemoveItemsButton = () => {
    return (
      <Button
        variant={ButtonVariants.TERTIARY}
        text='Remove products'
        icon={ICONS.TRASH}
        dataTestid='remove-proposal-products-bulk-action'
        onClick={() => setShowRemoveProductsModal(true)}
      />
    )
  }

  return (
    <>
      {/* bulk delete products */}
      <RemoveProposalProductModal
        showModal={showRemoveProductsModal}
        setShowModal={setShowRemoveProductsModal}
        loadProducts={loadProducts}
      />
      <Box flex={{ alignItems: 'center' }}>
        <RenderRemoveItemsButton />
      </Box>
    </>
  )
}

export default BulkActionsProposalProducts
