import React, { useEffect, useState } from 'react'
import { Modal } from 'atoms'
import { patchConnections } from 'api/connection'
import PageHeaderWithSwitch from 'molecules/PageHeader/PageHeaderWithSwitch'
import { useToast } from '@teamfabric/copilot-ui'

type formDataTypes = {
  retailer_identifier: string
  level: string
  integration_type: string
  options: any
  status: string
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues: formDataTypes
}

const editableOptions = [
  {
    name: 'Ship From Retailer',
    code: 'return_to_retailer_warehouse',
    description:
      'Populate the "ship from" on all shipping labels generated from the platform with a merchant return address. Some carriers do no respect this address, so it should be viewed as cosmetic only.',
    value: '0',
  },
  {
    name: 'Auto-populate Invoice Data',
    code: 'enforce_invoice_integrity',
    description:
      'Auto-populate all supplier invoice data except Invoice Number. Enabling this feature may reduce invoicing discrepancies, but suppliers will not be able to submit custom adjustments.',
    value: '0',
  },
  {
    name: 'Merchandise via Proposals?',
    code: 'merchandise_via_proposals',
    description:
      'Require suppliers to submit proposals to onboard product information.',
    value: '0',
  },
  {
    name: 'Require Pricing Approval On Proposals?',
    code: 'require_pricing_approval_on_proposals',
    description:
      'Add an additional pricing approval step to the proposal workflow.',
    value: '0',
  },
  {
    name: 'Include 850 In Packing Slip?',
    code: 'include_850_packing_slip',
    description:
      'Include a link to the packing slip in the 850 PO (EDI Suppliers only)',
    value: '0',
  },
  {
    name: 'Create Permits Automatically (Staff)',
    code: 'create_permits_on_product_create',
    description: 'Gain access to products as soon as they are created.',
    value: '0',
    restricted: true,
  },
  {
    name: 'Auto Invoice And Close Order When Fulfilled',
    code: 'auto_invoice_and_close_on_order_fulfilled',
    description:
      'As soon as an order is fully shipped, automatically generate an invoice and close the order.',
    value: '0',
  },
]

const UpdateSettingsModal: React.FC<ChildProps> = ({
  initialValues,
  reload,
  ...props
}) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(false)
  const [optionValues, setOptionValues] = useState([])
  const [options, setOptions] = useState([])

  const setOptionValue = (code, value) => {
    setOptionValues((prevOptionValues) =>
      prevOptionValues.map((el) => (el.code === code ? { ...el, value } : el))
    )
  }

  useEffect(() => {
    const connectionOptions = initialValues.options
    const optionsArray = Object.values(connectionOptions)
    const result = editableOptions.map((el) => {
      if (optionsArray.some((e: { code: string }) => e.code === el.code)) {
        el.value = '1'
      }
      return el
    })
    setOptions(result)
    const optionValuesResult = result.map((el) => {
      return {
        code: el.code,
        value: el.value,
      }
    })
    setOptionValues(optionValuesResult)
  }, [initialValues])

  const UpdateConnection = async () => {
    const body = {
      connection_options: optionValues.reduce((acc, { code, value }) => {
        acc[code] = value === '1'
        return acc
      }, {}),
    }
    try {
      setLoading(true)
      await patchConnections({
        connectionId: props.connectionId,
        body: body,
      })
      showToast({
        label: `Your connection settings, has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error updating the connection: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.onClose()
      reload()
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Update Connection Settings'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => UpdateConnection(),
            text: 'Save',
            variant: 'primary',
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        {options.map((option) => (
          <PageHeaderWithSwitch
            header={option.name}
            description={option.description}
            initialValue={option.value}
            onToggle={(value) => setOptionValue(option.code, value)}
            customClassName='mb-6'
          />
        ))}
      </Modal>
    </>
  )
}

export default UpdateSettingsModal
