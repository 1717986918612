//importing directly from the lib as the ToolTip doesn't render otherwise
import { InteractiveIcon } from '@teamfabric/copilot-ui'
import { Box, ToolTip } from 'atoms'
import { FC, useMemo } from 'react'

interface RowActionsProps {
  data: any
  setShowEditModal: (val) => void
  setShowDeleteModal?: (val) => void
  setSelected: () => void
  showDelete?: boolean
}

const ActionsMenu: FC<RowActionsProps> = ({
  data,
  setShowEditModal,
  setShowDeleteModal,
  setSelected,
  showDelete = true,
}) => {
  const options = useMemo(() => {
    let options = [
      {
        onClick: () => {
          setShowEditModal(true)
          setSelected()
        },
        icon: 'Pencil',
        tooltipText: 'Edit',
      },
    ]
    if (showDelete) {
      options.push({
        onClick: () => {
          setShowDeleteModal(true)
          setSelected()
        },
        icon: 'Trash',
        tooltipText: 'Delete',
      })
    }

    return options
  }, [data])

  return (
    <Box flex gap={16}>
      {options.map((option, index) => (
        <div key={index}>
          <ToolTip text={option.tooltipText}>
            <InteractiveIcon
              onClick={() => option.onClick()}
              iconName={option.icon}
              size={16}
            />
          </ToolTip>
        </div>
      ))}
    </Box>
  )
}

export default ActionsMenu
