import React from 'react'
import { Modal as ModalUI } from '@teamfabric/copilot-ui'

export type ModalType = 'small' | 'medium' | 'large' | 'xlarge'
export type ModalVariant = 'spinner' | 'normal'

type ModalProps = {
  headerText: any
  description?: any
  onClose?: () => void
  isVisible: boolean
  footerButtons?: any
  dataTestid?: string
  size?: ModalType
  onBackdropClick?: () => void
  children?: React.ReactNode
  variant?: ModalVariant
}

const Modal: React.FC<ModalProps> = ({ ...props }) => {
  return (
    <ModalUI
      {...props}
      dataTestid='test-id'
      size={props.size ? props.size : 'small'}
    />
  )
}

export default Modal
