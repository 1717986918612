import React from 'react'
import Link from 'atoms/link/link'
import { FILTERS } from '../constants'
import { handleResetAllFilters } from 'lib/utils/filter/singleFilters'
import { VARIANTS } from 'lib/constants'

type ChildProps = {
  isAFilterApplied: boolean
  resetAllFilters: any
}

const ResetAllFiltersButton: React.FC<ChildProps> = ({
  isAFilterApplied,
  resetAllFilters,
}) => {
  return (
    <>
      {isAFilterApplied && (
        <Link
          label={FILTERS.TEXT.RESET_ALL}
          mode='standalone'
          onClick={() => handleResetAllFilters(resetAllFilters)}
          theme='light'
          variant={VARIANTS.PRIMARY}
        />
      )}
    </>
  )
}

export default ResetAllFiltersButton
