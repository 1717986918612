import React, { useEffect, useMemo, useState } from 'react'
import HorizontalTab from 'atoms/tab'
import { capitalize } from 'lodash'
import InspectItemTabContent from './inspectItemTabContent'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'

type InspectItemProps = {
  variant: any
}

const InspectItemModal: React.FC<InspectItemProps> = ({ variant }) => {
  const [label, setLabel] = useState(0)
  const [itemAttributes, setItemAttributesData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const rulesetTabLabels = useMemo(() => {
    let labels = []
    variant?.ruleset_variants?.forEach((ruleset) =>
      labels.push(`${ruleset.ruleset?.name} (${capitalize(ruleset.status)})`)
    )
    return labels
  }, [variant])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      formatAttributeData(variant)
      setLoading(false)
    })()
  }, [])

  const proposalVariantCost = useMemo(() => {
    let cost = null
    if (
      variant.proposal_variant.attributes &&
      variant.proposal_variant.attributes.retailer_cost
    ) {
      let retailerCost =
        variant.proposal_variant.attributes.retailer_cost.values[0]
      if (variant.proposal_variant.attributes.retailer_cost.values.length > 1) {
        const values = variant.proposal_variant.attributes.retailer_cost.values
        // fetch the last created value
        retailerCost = values.reduce((a, b) =>
          a.created_at > b.created_at ? a : b
        )
      }
      cost = {
        value: retailerCost.value,
        currency: retailerCost,
      }
    }
    return cost
  }, [variant])

  const proposalVariantPrice = useMemo(() => {
    let price = null
    if (
      variant.proposal_variant.attributes &&
      variant.proposal_variant.attributes.retailer_price
    ) {
      price = {
        value:
          variant.proposal_variant.attributes.retailer_price.values[0].value,
        currency: variant.proposal_variant.attributes.retailer_price.values[0],
      }
    }
    return price
  }, [variant])

  const formatAttributeData = (variant) => {
    let formattedData = [
      {
        label: 'Variant ID',
        value: variant?.id ? variant.id : '--',
      },
      {
        label: 'Proposal Variant ID',
        value: variant?.proposal_variant?.id
          ? variant?.proposal_variant.id
          : '--',
      },
    ]

    if (variant?.active_permit) {
      let value = `${variant?.active_permit.id}`
      if (variant?.permits?.length > 2) {
        value += `(${variant.permits.length} permits)`
      }
      formattedData.push({
        label: 'Active Permit ID',
        value: value,
      })
    }

    if (proposalVariantCost !== null) {
      formattedData.push({
        label: 'Proposal Variant Retailer Cost',
        value: formatCurrency(proposalVariantCost.value),
      })
    }

    if (proposalVariantPrice !== null) {
      formattedData.push({
        label: 'Proposal Variant Retailer Price',
        value: formatCurrency(proposalVariantPrice.value),
      })
    }
    setItemAttributesData(formattedData)
  }

  return (
    <>
      <HorizontalTab
        onTabChange={setLabel}
        data={{
          tabs: [
            {
              label: rulesetTabLabels[0],
              content: (
                <InspectItemTabContent
                  itemAttributesInfo={itemAttributes}
                  variantAttributes={variant?.attributes}
                  ruleset={variant?.ruleset_variants[0]}
                  loading={loading}
                />
              ),
            },
            {
              label: rulesetTabLabels[1],
              content: (
                <InspectItemTabContent
                  itemAttributesInfo={itemAttributes}
                  variantAttributes={variant?.attributes}
                  ruleset={variant?.ruleset_variants[1]}
                  loading={loading}
                />
              ),
            },
          ],
        }}
      />
    </>
  )
}

export default InspectItemModal
