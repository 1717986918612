import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSelected, setSorting } from 'store/proposalsSlice'
import Link from 'atoms/link/link'
import { formatDate } from 'lib/utils/date/formatDate'
import ListName from '../presenters/listName'
import ProposalBadgeStatus from '../presenters/proposalBadgeStatus'
import { PRODUCTS } from 'modules/constants/products'
import { useNavigate } from 'react-router-dom'
import { formatProposalType } from 'modules/proposals/utils'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  selected: string[]
  isRetailer: boolean
}

const ProposalsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.selected])

  const tableColumns = [
    {
      title: 'ID',
      width: '5%',
    },
    //TODO: make dynamic for retailer/brand
    {
      title: 'Supplier Name',
    },
    {
      title: 'Category and Type',
    },
    {
      title: '# Items',
      width: '8%',
    },
    {
      title: 'Proposed',
      width: '12%',
    },
    {
      title: 'Updated',
      width: '12%',
    },
    {
      title: 'Status',
      width: '14%',
    },
  ]

  const isSelected = (id: string) => {
    return props?.selected?.includes(id)
  }
  const path = props.isRetailer ? '/proposals' : '/products/proposals'

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      ({
        id,
        connection,
        department,
        proposal_type,
        item_count,
        updated_at,
        proposed_at,
        acknowledged_at,
        status,
      }) => {
        return {
          id: id,
          selected: isSelected(id),
          data: {
            'ID': {
              onRender: () => (
                <Link
                  label={id}
                  variant='primary'
                  onClick={() => navigate(`${path}/${id}`)}
                  mode='inline'
                />
              ),
            },
            //TODO: make dynamic for retailer/brand
            'Supplier Name': { value: connection?.brand?.name },
            'Category and Type': {
              onRender: () => (
                <>
                  <p className='body1-regular grey-1000'>
                    {department?.name ? department.name : '---'}
                  </p>
                  <p className='body2-regular grey-700'>
                    {formatProposalType(proposal_type)}
                  </p>
                </>
              ),
            },
            '# Items': { value: item_count },
            'Proposed': { value: formatDate({ date: proposed_at }) },
            'Updated': { value: formatDate({ date: updated_at }) },
            'Status': {
              onRender: () => (
                <ProposalBadgeStatus
                  status={status}
                  acknowledgedAt={acknowledged_at}
                />
              ),
            },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  //TODO: create custom hooks for selecting rows to handle this centrally?
  const handleSingleRowSelect = (e, rowDetails: any, selected: boolean) => {
    if (selected) {
      dispatch(setSelected([rowDetails.id, ...props.selected]))
    } else {
      const newSelectedItemsIds = props.selected.filter(
        (selectedItemId) => selectedItemId !== rowDetails.id
      )
      dispatch(setSelected(newSelectedItemsIds))
    }
  }

  const handleAllRowSelect = (e, allRowsSelected: boolean) => {
    if (!allRowsSelected) {
      dispatch(setSelected([]))
    } else {
      const allItemIds = tableData.map((item) => item.id)
      dispatch(setSelected(allItemIds))
    }
  }

  const handleSort = (key: string) => {
    const mappedKey = PRODUCTS.SORTING_MAPS.PROPOSALS[key]
    let newSortBy = ''
    // if sorting by the current sorting key, change sorting direction, otherwise sort descending by new key
    if (mappedKey.replace('-', '') == props.currentSortBy.replace('-', '')) {
      newSortBy =
        props.currentSortBy.charAt(0) == '-'
          ? props.currentSortBy.slice(1)
          : `-${props.currentSortBy}`
    } else {
      newSortBy = `-${mappedKey}`
    }
    dispatch(setSorting(newSortBy))
    dispatch(setCurrentPage(1))
  }

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      loading={props.loading}
      // TODO: switch selectable to true once Bulk Archive implemented on the BE https://yottadv.atlassian.net/browse/MKP-3354 and finalized on FE
      selectable={false}
      onRowSelect={(e, rowDetails, selected) =>
        handleSingleRowSelect(e, rowDetails, selected)
      }
      onAllRowSelect={(e, allRowsSelected) =>
        handleAllRowSelect(e, allRowsSelected)
      }
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setPage(pageNumber)
        },
        activePageNumber: props.currentPage,
        perPage: 10,
        totalRecords: props.totalRows,
      }}
      onAscendingSort={(key: string) => handleSort(key)}
      onDescendingSort={(key: string) => handleSort(key)}
    />
  )
}

export default ProposalsTable
