import React from 'react'
import { Card, GridRow, GridCol, Button, ToolTip, Icon } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import Image from 'atoms/image/image'

type ChildProps = {
  loading?: boolean
  media: any[]
} & ({ onlyMedia: true; header?: any } | { onlyMedia?: false; header: any })

const ProductMediaCard: React.FC<ChildProps> = (props) => {
  const NoResultContent = () => {
    return <h5 className='h5 grey-500 textAlignCenter'>You have no media.</h5>
  }

  const RowContent = () => {
    return (
      <GridRow padding={false}>
        {props.media?.map((image, key) => (
          <GridCol sm={1} key={key}>
            <div className='textAlignCenter v-center'>
              <div className='ml-1'>
                <Image alt='item' size='medium' src={image.url} />
                <span className='grey-700 label'>
                  {image.width}x{image.height}
                </span>
              </div>
            </div>
          </GridCol>
        ))}
      </GridRow>
    )
  }

  return (
    <div className=''>
      {props.loading ? (
        <CardLoading
          label={props?.header}
          num={3}
          showCollapse={false}
          open={true}
        />
      ) : props?.onlyMedia ? (
        <RowContent />
      ) : (
        <Card
          headerContent={props?.header}
          showCollapse={false}
          open={true}
          showDivider={true}
          bodyContent={
            props?.media.length > 0 ? <RowContent /> : <NoResultContent />
          }
        />
      )}
    </div>
  )
}

export default ProductMediaCard
