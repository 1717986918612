import { Modal } from 'atoms'
import MultiSelect from 'atoms/select/multiSelect'
import React, { useState, useEffect } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { getTags } from 'api/tags'
import { debounce } from 'lodash'

type TagModalProps = {
  setDisplayModal: (value: boolean) => void
  isVisible: boolean
  onClick: () => void // bulk add or bulk remove
  headerText?: string
  inputLabel?: string
  addTagButtonLabel?: string
  onRemove?: (tagId: number) => void // add remove
  onAdd?: (tag: string) => any // add remove
  commonTags?: any // bulk remove
  values?: any // add remove
  isBulk: boolean
  setUserSelectedTags: (tags: any) => void // passing tags to the parent
}

const TagModal: React.FC<TagModalProps> = ({
  setDisplayModal,
  isVisible,
  onClick,
  headerText,
  inputLabel,
  isBulk,
  addTagButtonLabel,
  onRemove,
  onAdd,
  values,
  commonTags,
  setUserSelectedTags,
}) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const showToast = useToast()

  const formatTags = (results: any) => {
    return results.map(({ id, name }) => ({
      id,
      label: name,
    }))
  }

  useEffect(() => {
    ;(async () => {
      const {
        data: { results },
      } = await getTags({})
      if (!isBulk) {
        const assignedIdTags = results.map(({ id, name }) => {
          const obj = values.find(({ label }) => label === name)
          return obj ? { id: obj.id, label: name } : { id, label: name }
        })
        setSelectedTags(values)
        setTags(assignedIdTags)
      } else {
        setTags(commonTags || formatTags(results))
      }
    })()
  }, [values, commonTags])

  const searchTags = async ({ searchText }) => {
    if (searchText === '') {
      setTags([])
      return
    }
    try {
      setLoading(true)
      if (commonTags) {
        const filtered = commonTags.filter(({ label }) =>
          label.includes(searchText)
        )
        setTags(filtered)
      } else {
        const {
          data: { results },
        } = await getTags({ params: { name_search: searchText } })
        const formattedData = formatTags(results)
        setTags(formattedData)
        if (formattedData.length <= 0) {
          setTags([{ id: searchText, label: searchText }])
        }
      }
      setLoading(false)
    } catch (error) {
      showToast({
        label: 'Error Searching Tags',
        isDismissable: true,
        id: 'error-search-tags',
        variant: 'error',
      })
    }
  }

  const onChange = async (e) => {
    if (isBulk) {
      setSelectedTags(e)
      setUserSelectedTags(e)
      return
    }
    setSaving(true)
    const target = selectedTags.length > e.length ? e : selectedTags
    const filterData = selectedTags.length > e.length ? selectedTags : e
    const labels = target.map(({ label }) => label)
    const filteredArray = filterData.filter(
      ({ label }) => !labels.includes(label)
    )
    if (selectedTags.length < e.length) {
      // Add tag
      const { id: newId } = await onAdd(filteredArray[0].label)
      setSelectedTags([
        ...selectedTags,
        { id: newId, label: filteredArray[0].label },
      ])
      setUserSelectedTags([
        ...selectedTags,
        { id: newId, label: filteredArray[0].label },
      ])
    } else {
      // Remove
      setSelectedTags(e)
      setUserSelectedTags(e)
      const labels = e.map(({ label }) => label)
      const removedTag = selectedTags.find(
        ({ label }) => !labels.includes(label)
      )
      await onRemove(removedTag.id)
    }
    setSaving(false)
  }

  return (
    <Modal
      headerText={headerText || 'Add Tags'}
      description={
        <MultiSelect
          label={inputLabel || 'Add Tags'}
          name='tags'
          width='100%'
          disabled={saving}
          onChange={(e) => onChange(e)}
          onClear={async () => {
            // TODO create Backend PUT endpoint
          }}
          value={selectedTags}
          options={tags}
          serverSideFiltering={{
            loading,
            onInputChange: debounce(async (searchText: string) => {
              if (searchText !== '') {
                return await searchTags({ searchText })
              }
            }, 1000),
          }}
        />
      }
      onClose={() => setDisplayModal(false)}
      isVisible={isVisible}
      footerButtons={[
        {
          onClick: () => setDisplayModal(false),
          text: 'Cancel',
          variant: 'secondary',
        },
        {
          onClick: async () => {
            setSaving(true)
            await onClick()
            setSaving(false)
            setDisplayModal(false)
          },
          isLoading: saving,
          isDisabled: isBulk && selectedTags.length === 0,
          text: addTagButtonLabel || 'Add Tags',
          variant: 'primary',
        },
      ]}
      size='small'
      onBackdropClick={() => setDisplayModal(false)}
    />
  )
}

export default TagModal
