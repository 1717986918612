import React, { useEffect, useMemo, useState } from 'react'
import ProposalIssuesTable from '../table/issues'
import { getProposalIssues } from 'api/proposals'
import { useParams } from 'react-router-dom'

type IssuesProps = {
  proposal: any
  isRetailer: boolean
  loading: boolean
  loadProposal: (quiet?: boolean) => void
}

const Issues: React.FC<IssuesProps> = ({ proposal, loadProposal }) => {
  const [loadingIssues, setLoadingIssues] = useState(true)
  const [issues, setIssues] = useState<any>([])
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      fetchProposalIssues()
    }
  }, [id])

  const fetchProposalIssues = async () => {
    setLoadingIssues(true)
    const { data } = await getProposalIssues({ proposalId: id })
    loadProposal(true)
    setIssues(data.results)
    setLoadingIssues(false)
  }

  return (
    <>
      <div className='mt-5'>
        <ProposalIssuesTable
          proposal={proposal}
          data={issues}
          loading={loadingIssues}
          loadProposalIssue={fetchProposalIssues}
        />
      </div>
    </>
  )
}

export default Issues
