export const AddressFormDefaultValues = {
  name1: '',
  street1: '',
  street2: '',
  postal_code: '',
  city: '',
  province: '',
  country: '',
  email: '',
  phone1: '',
  nickname: '',
}

export type AddressFormDataType = {
  name1: string
  name2?: string
  street1: string
  street2: string
  postal_code: string
  city: string
  province: string
  country: string
  email?: string
  phone1?: string
  nickname?: string
}

export const CountryOptions = [
  { id: '1', label: 'United States', name: 'US', disabled: false },
  { id: '2', label: 'Canada', name: 'CA', disabled: false },
  {
    id: '3',
    label: 'United Kingdom / Great Britain',
    name: 'GB',
    disabled: false,
  },
  { id: '4', label: 'Colombia', name: 'CO', disabled: false },
  { id: '5', label: 'France', name: 'FR', disabled: false },
  { id: '6', label: 'Germany', name: 'DE', disabled: false },
  { id: '7', label: 'Italy', name: 'IT', disabled: false },
  { id: '8', label: 'Lithuania', name: 'LT', disabled: false },
  { id: '9', label: 'Martinique', name: 'MQ', disabled: false },
  { id: '10', label: 'Seychelles', name: 'SC', disabled: false },
  { id: '11', label: 'Spain', name: 'ES', disabled: false },
  { id: '12', label: 'Turkey', name: 'TR', disabled: false },
]
