import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import Link from 'atoms/link/link'
import { getPercentage } from 'lib/utils/math'
import ItemStatusPresenter from '../components/presenters/itemStatus'
import ProductsActionsMenu from '../components/presenters/productActionsMenu'
import VariantItem from 'molecules/lineItem/variantItem'

const formatProposalType = (type: string) => {
  switch (type) {
    case 'new_product':
      return 'New Products'
    case 'image_update':
      return 'Image Update'
    case 'attribute_update':
      return 'Attribute Update'
    default:
      return 'Cost Update'
  }
}

const formatRule = ({ key, rule, attribute, result }) => {
  let lineItem = {
    label: rule?.attribute?.name,
    value: null,
  }

  const value = attribute?.values[0]?.value

  if (rule?.attribute?.type === 'boolean') {
    if (value == 1) lineItem.value = 'Yes'
    else if (value == 0) lineItem.value = 'No'
    else lineItem.value = '-Not Set-'
  } else if (value) {
    if (rule?.attribute?.type === 'money') {
      lineItem.value = formatCurrency(value)
    } else if (rule?.attribute?.type === 'media') {
      lineItem.value = {
        onRender: () => (
          <Link
            label={'External URL'}
            onClick={() => window.open(value, '_blank')}
          />
        ),
      }
    } else {
      lineItem.value = `${value}${
        attribute?.values[0]?.unit ? ` ${attribute?.values[0]?.unit}` : ''
      }`
    }
  } else {
    lineItem.value = '-Not Set-'
  }

  return lineItem
}

const getRetailerCost = (row) => {
  let retailerCost = row.proposal_variant.pricing.retailer_cost.values[0]
  if (row.proposal_variant.pricing.retailer_cost.values.length > 1) {
    const values = row.proposal_variant.pricing.retailer_cost.values
    // fetch the last created value
    retailerCost = values.reduce((a, b) =>
      a.created_at > b.created_at ? a : b
    )
  }
  return formatCurrency(retailerCost?.value)
}

const getMargin = (pricing) => {
  function getValue(key) {
    let value = pricing[key].values[0].value
    if (pricing[key].values.length > 1) {
      const values = pricing[key].values
      // fetch the last created value
      value = values.reduce((a, b) =>
        a.created_at > b.created_at ? a : b
      ).value
    }
    return value
  }

  let cost = 0
  let price = 0

  if (pricing && 'retailer_cost' in pricing) {
    cost = parseFloat(getValue('retailer_cost'))
  }
  if (pricing && 'retailer_price' in pricing) {
    price = parseFloat(getValue('retailer_price'))
  }
  if (cost && price) {
    return getPercentage((price - cost) / price)
  } else return '--'
}

const transformProductChildrenToTableData = ({
  product,
  options,
  loadProposal,
  loadProducts,
}) => {
  return product.variants.map((item) => {
    return {
      data: {
        'Product': {
          onRender: () =>
            VariantItem({
              variant: item,
              showVariantDetails: true,
              showAdditionalActions: true,
              loadProducts: loadProducts,
              loadProposal: loadProposal,
            }),
        },
        'Cost': {
          value: item?.proposal_variant?.pricing?.retailer_cost
            ? getRetailerCost(item)
            : 'Not Set',
        },
        'Sugg. retail': {
          value: item?.proposal_variant?.pricing?.retailer_price
            ? formatCurrency(
                item?.proposal_variant?.pricing?.retailer_price?.values[0]
                  ?.value
              )
            : 'Not Set',
        },
        'Est. margin': {
          value: item?.proposal_variant
            ? getMargin(item?.proposal_variant?.pricing)
            : '--',
        },
        'Status': {
          onRender: () => <ItemStatusPresenter item={item} />,
        },
        'Actions': {
          onRender: () => (
            <ProductsActionsMenu
              product={item}
              options={options}
              loadProposal={loadProposal}
              loadProducts={loadProducts}
            />
          ),
        },
      },
      id: item.id,
      // children cannot be selected
      preserveDisabledCheckbox: false,
      disabled: true,
    }
  })
}
export { formatProposalType, formatRule, transformProductChildrenToTableData }
