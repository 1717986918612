import React, { useState } from 'react'
import { Box, Input, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { useNavigate, useParams } from 'react-router-dom'
import {
  cloneTemplate,
  createTemplateMapping,
  updateTemplateMapping,
} from 'api/templates'

type ChildProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  template: any
}

const CloneTemplateModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  template,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState('')

  const onSave = async () => {
    setSaving(true)
    try {
      await cloneTemplate({ id })
      showToast({
        label: 'Template cloned successfully.',
        isDismissable: true,
        id: '1',
      })
      navigate('/settings/templates')
    } finally {
      setSaving(false)
    }
  }

  return (
    <Modal
      headerText={'Clone Template'}
      description=''
      size='small'
      onClose={() => setShowModal(false)}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => setShowModal(false),
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onSave(),
          text: 'Confirm',
          variant: 'primary',
          isDisabled: !name || saving,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => setShowModal(false)}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <Input
          width='100%'
          label='Enter new name for template'
          required
          inputProps={{
            value: name,
            onChange: ({ target: { value } }) => setName(value),
          }}
        />
      </Box>
    </Modal>
  )
}

export default CloneTemplateModal
