import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import ProposalIssueStatusPresenter from '../presenters/issueStatus'
import { Table, Link } from 'atoms'
import InspectIssueModal from '../modals/issues/inspect'

type ChildProps = {
  data: any
  proposal: any
  loading: boolean
  loadProposalIssue: (quiet?: boolean) => void
}

const ProposalIssuesTable: FC<ChildProps> = (props) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showInspectIssueModal, setShowInspectIssueModal] = useState(false)
  const [selectedIssue, setSelectedIssue] = useState<any>({})

  useEffect(() => {
    if (!props.loading) {
      formatSetTableData({ data: props.data })
    }
  }, [props.loading, props.data])

  const tableColumns = [
    {
      title: 'Title',
      width: '30%',
    },
    {
      title: 'Message',
      width: '35%',
    },
    {
      title: 'Items',
      width: '10%',
    },
    {
      title: 'Notes',
      width: '10%',
    },
    {
      title: 'Status',
      width: '15%',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((issue) => {
      return {
        id: issue?.id,
        data: {
          Title: {
            onRender: () => (
              <>
                <Link
                  onClick={() => {
                    setShowInspectIssueModal(true)
                    setSelectedIssue(issue)
                  }}
                  label={issue.title}
                  mode='inline'
                />
              </>
            ),
          },
          Message: { value: issue.message },
          Items: { value: issue.elements.length },
          Notes: { value: issue.memos.length },
          Status: {
            onRender: () => <ProposalIssueStatusPresenter issue={issue} />,
          },
        },
      }
    })
    setTableData(formattedData)
  }

  return (
    <>
      <Table columns={tableColumns} data={tableData} loading={props.loading} />
      {/* inspect issue modal */}
      <InspectIssueModal
        showModal={showInspectIssueModal}
        setShowModal={setShowInspectIssueModal}
        proposal={props.proposal}
        issue={selectedIssue}
        loadProposalIssue={props.loadProposalIssue}
      />
    </>
  )
}

export default ProposalIssuesTable
