import { FC, useEffect, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { Box, Button, Dropdown, GridCol, GridRow, Input } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { theme, useToast } from '@teamfabric/copilot-ui'
import {
  getTemplateById,
  getTemplateMappings,
  updateTemplate,
} from 'api/templates'
import { capitalizeFirstLetter } from 'lib/utils'
import { DropDownInitialObj } from 'lib/utils/initialValue'
import CardLoading from 'molecules/loading/cardLoading'
import MappingsTable, {
  MappingType,
} from '../components/templates/mappingsTable'
import { downloadSampleFile } from 'api/products'
import CloneTemplateModal from '../components/templates/cloneTemplateModal'

const directionOptions: DropDownInitialObj[] = [
  {
    id: '1',
    label: 'Import',
    value: 'import',
  },
  {
    id: '2',
    label: 'Export',
    value: 'export',
  },
]

const ProductInventoryTemplateDetails: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const showToast = useToast()
  const [loading, setLoading] = useState(true)
  const [loadingMappings, setLoadingMappings] = useState(true)

  const [name, setName] = useState('')
  const [selectedDirection, setSelectedDirection] = useState('import')
  const [saving, setSaving] = useState(false)
  const [templateDetails, setTemplateDetails] = useState(null)
  const [templateMappings, setTemplateMappings] = useState<MappingType[]>([])
  const [templateMappingsCount, setTemplateMappingsCount] = useState(0)
  const [showCloneModal, setShowCloneModal] = useState(false)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.productInventoryTemplate({ page: `Template #${id}` })
        ),
      0
    )
  }, [])

  const onSave = async () => {
    const body = {
      name: name,
      direction: templateDetails.direction,
      data_type: templateDetails.data_type,
    }
    try {
      setSaving(true)
      await updateTemplate({ id, body })
      showToast({
        label: 'Template updated!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const fetchTemplate = async () => {
    const { data } = await getTemplateById({ id })
    setTemplateDetails(data)
    setName(data.name)
    setSelectedDirection(data.direction)
    setLoading(false)
  }

  const fetchTemplateMappings = async (quiet?: boolean) => {
    if (!quiet) setLoadingMappings(true)
    const { data } = await getTemplateMappings({
      id: id,
      params: {
        pagination: 0,
      },
    })
    setTemplateMappings(data.results)
    setTemplateMappingsCount(data.count)
    setLoadingMappings(false)
  }

  useEffect(() => {
    if (id) {
      ;(async () => {
        await fetchTemplate()
        await fetchTemplateMappings()
      })()
    }
  }, [id])

  const downloadSample = async () => {
    const link = document.createElement('a')
    link.target = '_blank'

    if (templateDetails?.sample_location) {
      link.href = templateDetails.sample_location
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Remove the link element after clicking
    } else {
      // Download template mapping, if sample location is not provided
      await downloadSampleFile(templateDetails.id, templateDetails.name)
    }
  }

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      {
        <CloneTemplateModal
          showModal={showCloneModal}
          setShowModal={setShowCloneModal}
          template={templateDetails}
        />
      }
      <PageHeaderComponent
        loading={loading}
        h1Text={`${capitalizeFirstLetter(templateDetails?.name || '')} ${id}`}
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings/templates'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: saving || !name,
          },
        ]}
      />
      {loading ? (
        <CardLoading />
      ) : (
        <Box
          border={{ radius: 2, width: '2px', color: theme.color.grey[200] }}
          padding={{ top: 4, bottom: 4, left: 4, right: 4 }}
        >
          <PageHeaderComponent
            h1Text='Template Settings'
            h2Text='Configure basic settings for this template.'
            customClassName='mb-4'
          />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Input
                width='100%'
                label='Template Name'
                required
                inputProps={{
                  value: name,
                  onChange: ({ target: { value } }) => setName(value),
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={3} md={3} sm={3}>
              <Input
                width='100%'
                label='Data type'
                inputProps={{
                  disabled: true,
                  value: capitalizeFirstLetter(templateDetails?.data_type),
                }}
              />
            </GridCol>
            <GridCol lg={3} md={3} sm={3}>
              <Dropdown
                width='100%'
                dataTestid='level-dropdown'
                label='Direction'
                disabled
                value={directionOptions.find(
                  (el) =>
                    el.value.toLowerCase() ===
                    templateDetails?.direction.toLowerCase()
                )}
                onChange={(value) => {}}
                options={directionOptions}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={3} md={3} sm={3}>
              <Button
                text='Clone Template'
                variant='secondary'
                onClick={() => setShowCloneModal(true)}
              />
            </GridCol>
            <GridCol lg={3} md={3} sm={3}>
              <Button
                text='Download Sample'
                variant='tertiary'
                onClick={() => downloadSample()}
              />
            </GridCol>
          </GridRow>
        </Box>
      )}
      <MappingsTable
        direction={selectedDirection}
        loading={loadingMappings}
        mappings={templateMappings}
        loadMappings={() => fetchTemplateMappings(true)}
        totalCount={templateMappingsCount}
      />
    </Box>
  )
}

export default ProductInventoryTemplateDetails
