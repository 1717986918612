import FilterModal from 'atoms/filter/filterModal'
import {
  applyFilters,
  resetLocalState,
  setInitialState,
} from 'lib/utils/filter/filterModal/actions'
import { date, radio } from 'lib/utils/filter/filterModal/itemTypes'
import { PRODUCTS } from 'modules/products/constants'
import { FILTERS } from 'molecules/filter/constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { setCreatedOn, setFilters } from 'store/proposalsSlice'

type AllFiltersModalProps = {
  params: any
}

const ProposalsAllFiltersModal: React.FC<AllFiltersModalProps> = ({
  params,
}) => {
  const [localAcknowledged, setLocalAcknowledged] = useState(null)
  const [localCreatedOn, setLocalCreatedOn] = useState(null)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [createdOnCounter, setCreatedOnCounter] = useState(0)

  const createdOn = useSelector((state: RootState) => state.proposals.createdOn)

  const acknowledged = useMemo(() => {
    if (params?.is_acknowledged === '0') {
      return 'No'
    } else if (params?.is_acknowledged === '1') {
      return 'Yes'
    } else return null
  }, [params?.is_acknowledged])

  const allFiltersCount = useMemo(() => {
    return createdOnCounter + acknowledgedCounter
  }, [createdOnCounter, acknowledgedCounter])

  // Define respective filterKey fns
  const filterActions = {
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      setLocalState: setLocalAcknowledged,
      filterType: FILTERS.TYPES.RADIO,
      label: PRODUCTS.ACKNOWLEDGED.NAME + '?',
      initialState: acknowledged,
      selectedValue: localAcknowledged,
    },
    created_at_gte: {
      setFilterCount: setCreatedOnCounter,
      setLocalState: setLocalCreatedOn,
      initialState: createdOn,
      date: localCreatedOn,
      action: setCreatedOn,
      filterType: FILTERS.TYPES.DATE,
      label: PRODUCTS.CREATED_ON.NAME,
    },
  }

  useEffect(() => {
    setInitialState(filterActions)
  }, [params])

  return (
    <FilterModal
      filters={[
        radio(filterActions, PRODUCTS.ACKNOWLEDGED.PARAM),
        date(filterActions, PRODUCTS.CREATED_ON.PARAM_START),
      ]}
      onApply={() => applyFilters(filterActions, setFilters)}
      onCancel={() => setInitialState(filterActions)}
      onResetAll={() => resetLocalState(filterActions)}
      primaryButtonText={`Apply (${allFiltersCount})`}
    />
  )
}

export default ProposalsAllFiltersModal
