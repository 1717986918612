import { FC, useEffect, useState } from 'react'
import { Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import SearchBar from 'molecules/search/searchBarTable'
import { setSearch } from 'store/productInventoryTemplatesSlice'
import { DataProps } from '@teamfabric/copilot-ui'
import { getTemplates } from 'api/templates'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import TemplatesFilter from '../components/templates/filters'
import TemplatesTable from '../components/templates/listTable'
import { useNavigate } from 'react-router-dom'

const ProductInventoryTemplates: FC = () => {
  const params = useSelector(
    (state: RootState) => state.productInventoryTemplates.params
  )
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Templates' })
        ),
      0
    )
  }, [])

  const fetchTemplates = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data: data } = await getTemplates({ params: { ...params } })
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoading(false)
  }

  useEffect(() => {
    if (params) {
      ;(async () => {
        await fetchTemplates()
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Templates'
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('create'),
            text: 'Create Template',
            variant: 'primary',
            icon: 'Add',
            iconPosition: 'left',
          },
        ]}
      />
      <SearchBar placeholder='Search within results...' setSearch={setSearch} />
      <div className='filterDiv'>
        <TemplatesFilter params={params} />
      </div>
      <TemplatesTable
        data={tableData}
        loading={loading}
        currentPage={params.page}
        currentSortBy={params.order_by}
        totalRows={totalRows}
      />
    </Box>
  )
}

export default ProductInventoryTemplates
