import { Tab as TabUI, TabItem } from '@teamfabric/copilot-ui'

type HorizontalTabProps = {
  data: {
    tabs: { label: string; content: React.ReactNode; isDisabled?: boolean }[]
  }
  onTabChange?: (num: number) => void
}

const HorizontalTab: React.FC<HorizontalTabProps> = ({ data, onTabChange }) => {
  return (
    <TabUI tabChangeHandler={(num) => onTabChange(num)}>
      {data.tabs.map(({ label, content, isDisabled }, index) => (
        <TabItem label={label} key={index} disabled={isDisabled}>
          {content}
        </TabItem>
      ))}
    </TabUI>
  )
}

export default HorizontalTab
