import Orders from 'pages/orders/dashboard'
import OrderList from 'pages/orders/list'
import OrderDetail from 'pages/orders/detail'
import OrderTracking from 'pages/orders/tracking'
import OrderReturns from 'pages/orders/returns'
import OrderInvoices from 'pages/orders/invoices'
import OrderCreate from 'pages/orders/create'
import OrderCancellations from 'pages/orders/cancellations'
import OrderCreditMemos from 'pages/orders/creditMemos'
// removing products dashboard
// import Products from 'pages/products/dashboard'
import ProductList from 'pages/products/list'
import ProductDetail from 'pages/products/detail'
import OfferList from 'pages/products/offers'
import ImportRequestList from 'pages/products/importRequests'
import ImportProduct from 'pages/products/importProduct'
import ImportRequestDetail from 'pages/products/importRequestDetail'
import ProposalsList from 'pages/proposals/list'
import ProposalDetail from 'pages/proposals/detail'
import ProposalProductDetail from 'pages/products/proposalProductDetail'
import AuthLogin from 'pages/auth/login'
import Onboarding from 'pages/onboarding/dashboard'
import OnboardingProgress from 'pages/onboarding/progress'
import SwitchContext from 'pages/context/dashboard'
import MerchantList from 'pages/merchants/list'
import MerchantDetail from 'pages/merchants/detail'
import SettingsDashboard from 'pages/settings/dashboard'
import BasicSettings from 'pages/settings/basicDetails'
import CurrencySettings from 'pages/settings/currency'
import NotificationSettings from 'pages/settings/notifications'
import PaymentSettings from 'pages/settings/payments'
import InventorySettings from 'pages/settings/inventory'
import UserManagementSettings from 'pages/settings/userManagements'
import APIClientsSettings from 'pages/settings/apiClients'
import IntegrationSettings from 'pages/settings/integrations'
import WebhookSettings from 'pages/settings/webhooks'
import ProposalDepartmentSettings from 'pages/settings/proposalDepartments'
import ProposalDepartmentSettingsDetails from 'pages/settings/proposalDepartmentDetails'
import AttributeValueTransformerSettings from 'pages/settings/attributeValueTransformers'
import ProductInventoryTemplateSettings from 'pages/settings/productInventoryTemplates'
import ShippingAccountSettings from 'pages/settings/shippingAccounts'
import TermsAndServicesSettings from 'pages/settings/termsAndServices'
import RetailerSkuSettingsPage from 'pages/settings/retailerSkuSettings'
import OnboardingSettings from 'pages/settings/onboarding'
import ProductInventoryTemplateCreate from 'pages/settings/productInventoryTemplateCreate'
import ProductInventoryTemplateDetails from 'pages/settings/productInventoryTemplateDetails'
import RulesetsSettings from 'pages/settings/rulesets'
import RulesetDetails from 'pages/settings/rulesetDetails'
import RuleGroupSettings from 'pages/settings/ruleGroupDetails'
import SuppliersDashboard from 'pages/suppliers/dashboard'
import SuppliersList from 'pages/suppliers/list'
import SuppliersInvitations from 'pages/suppliers/invitations'
import SuppliersLocations from 'pages/suppliers/locations'
import SuppliersInvite from 'pages/suppliers/invite'
import InventorySummary from 'pages/products/inventorySummary'
import SupplierDetail from 'pages/suppliers/detail'
import EmptyPageTemplate from 'pages/emptyPage'
// import AdminOverWrite from 'pages'

export const PATHS = {
  // AdminOverWrite: '/admin/activity',
  OrdersDashboard: '/overview',
  OrderList: '/orders',
  OrderDetail: '/orders/:id',
  OrderTracking: '/orders/tracking',
  OrderReturns: '/orders/returns',
  OrderInvoices: '/orders/invoices',
  OrderCancellations: '/orders/cancellations',
  OrderCreditMemos: '/orders/credits',
  OrderCreate: '/orders/create',
  ProductList: '/products',
  Onboarding: '/onboarding',
  OnboardingProgress: '/onboarding/:name',
  // ProductList: '/products/list',
  ProductDetail: '/products/:id',
  OfferList: '/products/offers',
  ImportRequests: '/products/import-requests',
  ImportRequest: '/products/import-requests/:id',
  ImportProduct: '/products/import-product',
  ProposalsList: '/products/proposals',
  ProposalDetail: '/products/proposals/:id',
  ProposalProductDetail: '/products/proposals/:id/products/:productId',
  InventorySummary: '/products/inventory-summary',
  Login: '/login',
  SwitchContext: '/account',
  Merchants: '/merchants',
  MerchantDetail: 'merchants/:id',
  SettingsDashboard: '/settings',
  BasicSettings: '/settings/basic-details',
  CurrencySettings: '/settings/currency',
  NotificationSettings: '/settings/notifications',
  PaymentSettings: '/settings/payment',
  InventorySettings: '/settings/inventory',
  UserManagementSettings: '/settings/users',
  APIClientsSettings: '/settings/api',
  IntegrationSettings: '/settings/integrations',
  WebhookSettings: '/settings/webhooks',
  ProposalDepartmentSettings: '/settings/proposal-departments',
  ProposalDepartmentSettingsDetails: '/settings/proposal-departments/:id',
  AttributeValueTransformerSettings: '/settings/attribute-transformers',
  ProductInventoryTemplateSettings: '/settings/templates',
  ProductInventoryTemplateCreate: '/settings/templates/create',
  ProductInventoryTemplateDetails: '/settings/templates/:id',
  ShippingAccountSettings: '/settings/shipping-accounts',
  TermsAndServicesSettings: '/settings/terms-services',
  RetailerSkuSettings: '/settings/retailer-sku',
  OnboardingSettings: '/settings/onboarding',
  SuppliersDashboard: '/',
  SuppliersDashboardWithCalendar: '/dashboard',
  // SuppliersList: '/list',
  SuppliersInvite: '/invite',
  SupplierDetail: '/:id',
  SuppliersInvitations: '/invitations',
  SuppliersLocations: '/locations',
  complianceScorecard: '/reports/compliance-scorecard',
  complianceReporting: '/reports/compliance',
  jobs: '/reports/jobs',
  exceptions: '/reports/exceptions',
  RulesetsSettings: '/settings/rulesets',
  RulesetDetails: '/settings/rulesets/:id',
  RuleGroupSettings: '/settings/rulesets/:id/rulegroup/:ruleGroupId',
}

const RETAILER_PATHS = {
  ProposalsList: '/proposals',
  ProposalDetail: '/proposals/:id',
  ProposalProductDetail: '/proposals/:id/products/:productId',
}

export const CommonRoutes = [
  { path: PATHS.OrdersDashboard, component: Orders },
  { path: PATHS.OrderList, component: OrderList },
  { path: PATHS.OrderDetail, component: OrderDetail },
  { path: PATHS.OrderTracking, component: OrderTracking },
  { path: PATHS.OrderCancellations, component: OrderCancellations },
  { path: PATHS.OrderCreditMemos, component: OrderCreditMemos },
  // { path: PATHS.ProductsDashboard, component: Products },
  { path: PATHS.ProductList, component: ProductList },
  { path: PATHS.OrderInvoices, component: OrderInvoices },
  { path: PATHS.ImportRequests, component: ImportRequestList },
  { path: PATHS.ImportRequest, component: ImportRequestDetail },
  // settings
  { path: PATHS.SettingsDashboard, component: SettingsDashboard },
  { path: PATHS.BasicSettings, component: BasicSettings },
  { path: PATHS.CurrencySettings, component: CurrencySettings },
  { path: PATHS.NotificationSettings, component: NotificationSettings },
  { path: PATHS.PaymentSettings, component: PaymentSettings },
  { path: PATHS.InventorySettings, component: InventorySettings },
  { path: PATHS.UserManagementSettings, component: UserManagementSettings },
  { path: PATHS.APIClientsSettings, component: APIClientsSettings },
  { path: PATHS.IntegrationSettings, component: IntegrationSettings },
  { path: PATHS.WebhookSettings, component: WebhookSettings },
  {
    path: PATHS.AttributeValueTransformerSettings,
    component: AttributeValueTransformerSettings,
  },
  {
    path: PATHS.ProductInventoryTemplateSettings,
    component: ProductInventoryTemplateSettings,
  },
  { path: PATHS.ShippingAccountSettings, component: ShippingAccountSettings },
  { path: PATHS.TermsAndServicesSettings, component: TermsAndServicesSettings },
]

export const BrandPageRoutes = [
  // { path: PATHS.AdminOverWrite, component: AdminOverWrite},
  ...CommonRoutes,
  { path: PATHS.OrderReturns, component: OrderReturns },
  // { path: PATHS.ProductsDashboard, component: Products },
  { path: PATHS.Onboarding, component: Onboarding },
  { path: PATHS.OnboardingProgress, component: OnboardingProgress },
  { path: PATHS.ProductDetail, component: ProductDetail },
  { path: PATHS.OfferList, component: OfferList },
  { path: PATHS.Login, component: AuthLogin },
  { path: PATHS.SwitchContext, component: SwitchContext },
  { path: PATHS.Merchants, component: MerchantList },
  { path: PATHS.MerchantDetail, component: MerchantDetail },
  { path: PATHS.complianceScorecard, component: EmptyPageTemplate },
  { path: PATHS.complianceReporting, component: EmptyPageTemplate },
  { path: PATHS.jobs, component: EmptyPageTemplate },
  { path: PATHS.exceptions, component: EmptyPageTemplate },
  { path: PATHS.ProposalsList, component: ProposalsList },
  { path: PATHS.ProposalDetail, component: ProposalDetail },
  { path: PATHS.ProposalProductDetail, component: ProposalProductDetail },
]

export const RetailerPageRoutes = [
  ...CommonRoutes,
  { path: PATHS.OrderCreate, component: OrderCreate },
  // dashboard would render list with tabs
  { path: PATHS.SuppliersDashboard, component: SuppliersList },
  { path: PATHS.SuppliersDashboardWithCalendar, component: SuppliersDashboard },
  // { path: PATHS.SuppliersList, component: SuppliersList },
  { path: PATHS.SuppliersInvite, component: SuppliersInvite },
  { path: PATHS.ImportProduct, component: ImportProduct },
  { path: PATHS.InventorySummary, component: InventorySummary },
  { path: PATHS.SupplierDetail, component: SupplierDetail },
  { path: PATHS.SuppliersInvitations, component: SuppliersInvitations },
  { path: PATHS.SuppliersLocations, component: SuppliersLocations },
  { path: RETAILER_PATHS.ProposalsList, component: ProposalsList },
  { path: RETAILER_PATHS.ProposalDetail, component: ProposalDetail },
  {
    path: RETAILER_PATHS.ProposalProductDetail,
    component: ProposalProductDetail,
  },
  { path: PATHS.RetailerSkuSettings, component: RetailerSkuSettingsPage },
  {
    path: PATHS.ProposalDepartmentSettings,
    component: ProposalDepartmentSettings,
  },
  {
    path: PATHS.ProposalDepartmentSettingsDetails,
    component: ProposalDepartmentSettingsDetails,
  },
  {
    path: PATHS.OnboardingSettings,
    component: OnboardingSettings,
  },
  {
    path: PATHS.ProductInventoryTemplateCreate,
    component: ProductInventoryTemplateCreate,
  },
  {
    path: PATHS.ProductInventoryTemplateDetails,
    component: ProductInventoryTemplateDetails,
  },
  { path: PATHS.RulesetsSettings, component: RulesetsSettings },
  { path: PATHS.RulesetDetails, component: RulesetDetails },
  { path: PATHS.RuleGroupSettings, component: RuleGroupSettings },
]
