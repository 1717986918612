import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSelected } from 'store/proposalProductsSlice'
import VariantItem from 'molecules/lineItem/variantItem'
import { useNavigate } from 'react-router-dom'
import { getTenantType } from 'api/helper'
import { GridCol, GridRow, Table } from 'atoms'
import { transformProductChildrenToTableData } from 'modules/proposals/utils'
import ProductsActionsMenu from '../presenters/productActionsMenu'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  selected: string[]
  proposal: any
  options: any
  loadProposal: (quiet?: boolean) => void
  loadProducts: (quiet?: boolean) => void
}

const ProposalProductsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isRetailer } = getTenantType()
  const path = isRetailer ? '/proposals' : '/products/proposals'

  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.selected])

  // merchant columns, revisit for vendors
  const tableColumns = [
    {
      title: 'Product',
    },
    {
      title: '# of Items',
      width: '12%',
    },
    {
      title: 'Cost',
      width: '12%',
    },
    {
      title: 'Sugg. retail',
      width: '12%',
    },
    {
      title: 'Est. margin',
      width: '12%',
    },
    {
      title: 'Status',
      width: '12%',
    },
    {
      title: 'Actions',
      width: '12%',
    },
  ]

  const isSelected = (id: string) => {
    return props?.selected?.includes(id)
  }

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((product) => {
      return {
        id: product?.id,
        selected: isSelected(product?.id),
        data: {
          'Product': {
            onRender: () =>
              VariantItem({
                variant: product,
                onClick: () =>
                  navigate(
                    `${path}/${props.proposal?.id}/products/${product?.id}`
                  ),
                isProduct: true,
              }),
          },
          '# of Items': { value: product.num_variants },
          'Actions': {
            onRender: () => (
              <ProductsActionsMenu
                product={product}
                options={props.options}
                isProduct={true}
                loadProposal={props.loadProposal}
                loadProducts={props.loadProducts}
              />
            ),
          },
        },
        children:
          product.num_variants > 0
            ? transformProductChildrenToTableData({
                product,
                options: props.options,
                loadProposal: () => props.loadProposal(),
                loadProducts: () => props.loadProducts(),
              })
            : undefined,
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  //TODO: create custom hooks for selecting rows to handle this centrally?
  const handleSingleRowSelect = (e, rowDetails: any, selected: boolean) => {
    if (selected) {
      dispatch(setSelected([rowDetails.id, ...props.selected]))
    } else {
      const newSelectedItemsIds = props.selected.filter(
        (selectedItemId) => selectedItemId !== rowDetails.id
      )
      dispatch(setSelected(newSelectedItemsIds))
    }
  }

  const handleAllRowSelect = (e, allRowsSelected: boolean) => {
    if (!allRowsSelected) {
      dispatch(setSelected([]))
    } else {
      const allItemIds = tableData.map((item) => item.id)
      dispatch(setSelected(allItemIds))
    }
  }

  return (
    <>
      <GridRow padding={false}>
        <GridCol>
          <Table
            canHaveChildren
            columns={tableColumns}
            data={tableData}
            loading={props.loading}
            selectable={true}
            onRowSelect={(e, rowDetails, selected) =>
              handleSingleRowSelect(e, rowDetails, selected)
            }
            onAllRowSelect={(e, allRowsSelected) =>
              handleAllRowSelect(e, allRowsSelected)
            }
            customPaginationProps={{
              handlePagination: (pageNumber: number) => {
                setPage(pageNumber)
              },
              activePageNumber: props.currentPage,
              perPage: 10,
              totalRecords: props.totalRows,
            }}
          />
        </GridCol>
      </GridRow>
    </>
  )
}

export default ProposalProductsTable
