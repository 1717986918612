export const SETTING_OPTIONS = [
  {
    Title: 'Basic Details',
    Description: 'Manage general information about your business.',
    path: 'basic-details',
    key: 'basic-details',
    iconName: 'Info',
  },
  {
    Title: 'Default Onboarding Preferences',
    Description: 'Manage default onboarding settings for all new connections.',
    path: 'onboarding',
    key: 'onboarding',
    iconName: 'Activate',
  },
  // {
  //   Title: 'Currency Settings',
  //   Description: 'Manage the currency of your account.',
  //   path: 'currency',
  //   key: 'currency-settings',
  //   iconName: 'Subscriptions',
  // },
  {
    Title: 'Notifications',
    Description:
      'Update the notifications you receive from the fabric Platform.',
    path: 'notifications',
    key: 'notifications',
    iconName: 'NotificationsNone',
  },
  // {
  //   Title: 'Payment Settings (Staff Only)',
  //   Description: 'Connect your bank account for automatic payments.',
  //   path: 'payment',
  //   key: 'payment-settings',
  //   iconName: 'Pay',
  // },
  // {
  //   Title: 'Inventory Settings',
  //   Description: 'Register and update fulfillment & inventory locations.',
  //   path: 'inventory',
  //   key: 'inventory-settings',
  //   iconName: 'Inventory',
  // },
  // {
  //   Title: 'User Management',
  //   Description: 'Invite new and manage existing users.',
  //   path: 'users',
  //   key: 'user-management',
  //   iconName: 'Customers',
  // },
  // {
  //   Title: 'API Clients',
  //   Description: 'Manage API keys for your account.',
  //   path: 'api',
  //   key: 'api-clients',
  //   iconName: 'DynamicValues',
  // },
  // {
  //   Title: 'Integrations',
  //   Description:
  //     'Manage integrations of your account (e.g. Shopify, fabric OMS).',
  //   path: 'integrations',
  //   key: 'integrations',
  //   iconName: 'Swap',
  // },
  // {
  //   Title: 'Webhooks(Advanced)',
  //   Description: 'Configure webhooks and review webhook history.',
  //   path: 'webhooks',
  //   key: 'webhooks',
  //   iconName: 'Webhook',
  // },
  {
    Title: 'Proposal Departments',
    Description: 'Manage your proposal departments.',
    path: 'proposal-departments',
    key: 'proposal-departments',
    iconName: 'File',
  },
  // {
  //   Title: 'Attribute Value Transformers',
  //   Description: 'Manage your product attribute value transformations.',
  //   path: 'attribute-transformers',
  //   key: 'attribute-transformers',
  //   iconName: 'Settings',
  // },
  {
    Title: 'Product Rulesets',
    Description: 'Manage your product rulesets.',
    path: 'rulesets',
    key: 'rulesets',
    iconName: 'Heirarchy',
  },
  {
    Title: 'Product & Inventory Templates',
    Description: 'Manage your product and inventory templates.',
    path: 'templates',
    key: 'templates',
    iconName: 'Logs',
  },
  // {
  //   Title: 'Shipping Accounts',
  //   Description: 'Manage existing and add new Shipping Accounts.',
  //   path: 'shipping-accounts',
  //   key: 'shipping-accounts',
  //   iconName: 'Orders',
  // },
  // {
  //   Title: 'Terms of Service',
  //   Description: 'Review the Terms of Service from fabric Marketplace.',
  //   path: 'terms-services',
  //   key: 'terms-services',
  //   iconName: 'List',
  // },
  {
    Title: 'Retailer SKU Settings',
    Description: 'Configure auto-generation of Retailer SKU.',
    path: 'retailer-sku',
    key: 'retailer-sku',
    iconName: 'Grid',
  },
]
