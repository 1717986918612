import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/productInventoryTemplatesSlice'
import Link from 'atoms/link/link'
import Box from 'atoms/box/box'
import { Badge } from 'atoms'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from 'lib/utils'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const TemplatesTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.data])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'Template name',
        width: '40%',
      },
      {
        title: 'Template ID',
        width: '15%',
      },
      {
        title: 'Data type',
        width: '15%',
      },
      {
        title: 'Direction',
        width: '15%',
      },
      {
        title: 'Mappings',
        width: '15%',
      },
    ]
    return columns
  }, [])

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((template, id) => {
      return {
        id,
        data: {
          'Template name': {
            onRender: () => (
              <Link
                onClick={() => navigate(`${template.id}`)}
                label={template?.name}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Template ID': { value: template.id },
          'Data type': { value: capitalizeFirstLetter(template?.data_type) },
          'Direction': { value: capitalizeFirstLetter(template?.direction) },
          'Mappings': { value: template?.mapping_field_count },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <>
      <Box dataTestid='templates-table' margin={{ bottom: 7 }}>
        <Table
          columns={tableColumns}
          data={tableData}
          loading={props.loading}
          selectable={false}
          customPaginationProps={{
            handlePagination: (pageNumber: number) => {
              setPage(pageNumber)
            },
            activePageNumber: props.currentPage,
            perPage: 10,
            totalRecords: props.totalRows,
          }}
        />
      </Box>
    </>
  )
}

export default TemplatesTable
