import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataProps } from '@teamfabric/copilot-ui'
import { RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import SupplierFilter from '../components/filter'
import SearchBar from 'molecules/search/searchBarTable'
import { getRetailerConnections, getConnectionSummary } from 'api/connection'
import SupplierTable from '../components/table'
import { setSearch } from 'store/suppliersSlice'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import { PAGE_TYPE } from 'lib/constants'
import PageHeaderComponent from 'molecules/PageHeader'
import Tab from 'atoms/tab/tab'
import { CONNECTIONS } from 'modules/constants/connections'
import { resetAllFilters } from 'store/suppliersSlice'
import MoreActionButtonChildren from 'molecules/button/moreDropdownChildren'

const SuppliersListingTemplate: FC = () => {
  const isInitialRender = useRef(true)
  const params = useSelector((state: RootState) => state.suppliers.params)
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [loading, setLoading] = useState(true)
  const [totalRows, setTotalRows] = useState(0)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [loadingConnectionSummary, setLoadingConnectionSummary] = useState(true)
  const [connectionSummary, setConnectionSummary] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const { data } = await getConnectionSummary()
      setConnectionSummary(data)
      setLoadingConnectionSummary(false)
    })()
    setTimeout(() => window.updateBreadcrumb(breadcrumbs.manageSuppliers()), 0)
  }, [])

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const filteredParams = {}
        // skipping empty keys
        for (const key in params) {
          if (params[key] !== '') {
            filteredParams[key] = params[key]
          }
        }
        const { data: data } = await getRetailerConnections({
          params: { ...filteredParams },
        })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  const TabHeaders = [
    {
      label: CONNECTIONS.SUPPLIER_TAB_PRESETS.ALL,
    },
    {
      label:
        CONNECTIONS.SUPPLIER_TAB_PRESETS.ACTIVE +
        (loadingConnectionSummary ? '' : ` (${connectionSummary?.active})`),
    },
    {
      label:
        CONNECTIONS.SUPPLIER_TAB_PRESETS.ONBOARDING +
        (loadingConnectionSummary ? '' : ` (${connectionSummary?.onboarding})`),
    },
    {
      label:
        CONNECTIONS.SUPPLIER_TAB_PRESETS.SUSPENDED +
        (loadingConnectionSummary ? '' : ` (${connectionSummary?.suspended})`),
    },
  ]

  useEffect(() => {
    // skip the first load
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    const tabPresetsMapping = {
      0: { statusPreset: '' },
      1: { statusPreset: 'active' },
      2: { statusPreset: 'onboarding' },
      3: { statusPreset: 'suspended' },
    }
    const resetAllFiltersPayload = tabPresetsMapping[activeTabIndex] || {}
    dispatch(resetAllFilters(resetAllFiltersPayload))
  }, [activeTabIndex])

  const MoreActions = [
    {
      name: 'View Invitations',
      handleClick: () => navigate('/invitations'),
    },
    {
      name: 'View Supplier Locations',
      handleClick: () => navigate('/locations'),
    },
  ]

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeaderComponent
            loading={false}
            h1Text='Manage Suppliers'
            primaryButtons={[
              {
                text: 'More actions',
                icon: 'ArrowDown',
                iconPosition: 'right',
                variant: 'secondary',
                onClick: () => {},
                popoverProps: {
                  children: <MoreActionButtonChildren actions={MoreActions} />,
                  placement: 'bottom-start',
                },
              },
              {
                onClick: () => navigate('/invite'),
                text: 'Invite Supplier',
                variant: 'primary',
              },
            ]}
            customClassName='mb-4'
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol md={12}>
          <Tab
            data={TabHeaders}
            setLabel={setActiveTabIndex}
            activeIndex={activeTabIndex}
            component={
              <Box width='100%'>
                <SearchBar
                  placeholder='Search for a supplier by name'
                  setSearch={setSearch}
                  className='mt-4'
                />
                <div className='mt-3 mb-0'>
                  <SupplierFilter params={params} />
                </div>
                <BulkActionsBar
                  loading={loading}
                  hasBulkActions={false}
                  totalRows={totalRows}
                  currentPage={params.page}
                  pageType={PAGE_TYPE.SUPPLIERS}
                />
                <SupplierTable
                  data={tableData}
                  loading={loading}
                  currentPage={params.page}
                  currentSortBy={params.order_by}
                  totalRows={totalRows}
                />
              </Box>
            }
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default SuppliersListingTemplate
