import React, { useEffect, useMemo, useState } from 'react'
import { getProposalRulesetSummary } from 'api/proposals'
import SelectableCardGrid from 'molecules/selectableCardGrid'
import { isNull, isUndefined } from 'lodash'
import { Banner } from 'atoms'
import { BANNER_VARIANT_OPTIONS } from '@teamfabric/copilot-ui'

type CompatibilityProps = {
  proposal: any
  isRetailer: boolean
}

const Compatibility: React.FC<CompatibilityProps> = ({
  proposal,
  isRetailer,
}) => {
  const [rulesets, setRulesets] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (proposal) {
      const fetchProposalRulesetSummary = async () => {
        const { data } = await getProposalRulesetSummary({ id: proposal?.id })
        setRulesets(data)
        setLoading(false)
      }
      fetchProposalRulesetSummary()
    }
  }, [proposal])

  const vendorRuleset = useMemo(() => {
    if (rulesets.length === 0) return null
    const ruleset = rulesets.find((ruleset) => {
      return ruleset.id === proposal?.department?.brand_ruleset?.id
    })
    return !isUndefined(ruleset) ? ruleset : null
  }, [rulesets, proposal])

  const isPassing = useMemo(() => {
    if (vendorRuleset) {
      return vendorRuleset.failed === 0 && vendorRuleset.processing === 0
    }
    return false
  }, [vendorRuleset])

  const passedOrFailedComponent = useMemo(() => {
    if (!loading && !isNull(vendorRuleset)) {
      if (isPassing) {
        return (
          <Banner
            label='All items are passing preliminary compatibility checks. Your proposal is almost ready!'
            variant={BANNER_VARIANT_OPTIONS.SUCCESS}
          />
        )
      } else {
        // TODO: return compatibility failed components
        console.log('compatibility failed component renders')
      }
    } else return ''
  }, [isPassing, loading, vendorRuleset])

  return (
    <>
      <h5 className='h5 mb-4 mt-5'>Compatibility results</h5>
      <SelectableCardGrid
        customClassName='mb-4'
        loading={loading}
        gridSize={3}
        data={[
          {
            label: 'Total Items',
            value: vendorRuleset ? vendorRuleset.count : '--',
            handleClick: () => {
              console.log('total detail')
            },
          },
          {
            label: 'Evaluating',
            value: vendorRuleset ? vendorRuleset.processing : '--',
            handleClick: () => {
              console.log('evaluating detail')
            },
          },
          {
            label: 'Items Passed',
            value: vendorRuleset ? vendorRuleset.passed : '--',
            handleClick: () => {
              console.log('items passed detail')
            },
          },
          {
            label: 'Items Failed',
            value: vendorRuleset ? vendorRuleset.failed : '--',
            handleClick: () => {
              console.log('items failed detail')
            },
          },
        ]}
      />
      {passedOrFailedComponent}
    </>
  )
}

export default Compatibility
