import API, { RETAILER_API_URL, API_URL } from 'api'

export const getShopifyTemplate = async ({ platformAccountId, params }) => {
  return await API.get(
    RETAILER_API_URL.SHOPIFY_TEMPLATE({ platformAccountId }),
    { params }
  )
}

export const getShopifyLookupOrder = async ({ id, params }) => {
  return await API.get(API_URL.SHOPIFY_LOOKUP_ORDER({ id }), { params })
}
