import moment from 'moment'

const formatDate = ({ date, withTime = false, withYear = true }) => {
  if (!date) {
    return '---'
  }
  const parsedDate = new Date(date)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const year = withYear ? `, ${parsedDate.getFullYear()}` : ''
  const time = withTime
    ? ` at ${parsedDate.toLocaleTimeString(['en-US'], {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    : ''
  return `${
    months[parsedDate.getMonth()]
  } ${parsedDate.getDate()}${year}${time}`
}

const getHoursAndMinutesFromDate = ({ date }) => {
  const parsedDate = new Date(date)
  const hours = parsedDate.getHours()
  let minutes = String(parsedDate.getMinutes())
  if (minutes.length === 1) minutes = '0' + minutes

  return `${hours}:${minutes}`
}

const getUnixTimestamp = (date) => {
  return Math.round(new Date(date).getTime() / 1000)
}

const getMMDDYYYY = (timestamp) => {
  const date = new Date(timestamp)
  const monthAndDay =
    ((date.getMonth() + 1).toString().length > 1
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate().toString().length > 1
      ? date.getDate()
      : '0' + date.getDate())
  const year = date.getFullYear()
  return monthAndDay + '/' + year
}

const get30YearsAgo = () => {
  return new Date(new Date().setDate(new Date().getDate() - 30 * 365))
}

const get30YearsFromNow = () => {
  return new Date(new Date().setDate(new Date().getDate() + 30 * 365))
}

const get29YearsAgo = () => {
  return new Date(new Date().setDate(new Date().getDate() - 29 * 365))
}

const get29YearsFromNow = () => {
  return new Date(new Date().setDate(new Date().getDate() + 29 * 365))
}

const getLastXdays = (numberOfDays: number) => {
  return new Date(new Date().setDate(new Date().getDate() - numberOfDays))
}

const getXMonthAgo = (numberOfMonths: number) => {
  var date = new Date()
  var m = date.getMonth()
  date.setMonth(date.getMonth() - numberOfMonths)

  // If still in same month, set date to last day of previous month
  if (date.getMonth() == m) date.setDate(0)
  date.setHours(0, 0, 0, 0)

  return date
}

const getXMonthAhead = (numberOfMonths: number) => {
  var date = new Date()
  var m = date.getMonth()
  date.setMonth(date.getMonth() + numberOfMonths)

  // If still in same month, set date to last day of previous month
  if (date.getMonth() == m) date.setDate(0)
  date.setHours(0, 0, 0, 0)

  return date
}

const getDateObjectForLastMonth = () => {
  const from = getXMonthAgo(1)
  const to = new Date()

  const dateObject = {
    value: 'lastMonth',
    dateRange: {
      from: from,
      to: to,
    },
  }
  return dateObject
}

const calculateDuration = (startTime, endTime) => {
  if (startTime && endTime) {
    const startDatetime = moment(startTime)
    const endDatetime = moment(endTime)
    return moment.duration(startDatetime.diff(endDatetime)).humanize()
  }
  return '--'
}

export {
  formatDate,
  getHoursAndMinutesFromDate,
  getUnixTimestamp,
  getMMDDYYYY,
  get30YearsAgo,
  get30YearsFromNow,
  get29YearsAgo,
  get29YearsFromNow,
  getLastXdays,
  getXMonthAgo,
  getXMonthAhead,
  getDateObjectForLastMonth,
  calculateDuration,
}
