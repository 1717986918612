import React from 'react'
import { PhoneNumber as PhoneNumberUI } from '@teamfabric/copilot-ui'

export type PhoneProps = {
  required?: boolean
  disabled?: boolean
  id?: string
  placeholder?: string
  inputProps?: any
  onChange?: (phoneNumber: string) => void
  onBlur?: (phoneNumber: string, isValid: boolean) => void
  defaultValue?: string
}

const PhoneNumber: React.FC<PhoneProps> = ({ ...props }) => {
  return <PhoneNumberUI {...props} />
}

export default PhoneNumber
