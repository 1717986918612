import React from 'react'
import { Card, GridRow, GridCol, Button } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'

type ChildProps = {
  loading?: boolean
  header?: any
  data: any[]
  skeletonRowsNumber?: number
  showCollapse?: boolean
  open?: boolean
  customClassName?: string
  noResultsText?: string
  headerWithButtons?: boolean
  headerButtonText?: string
  onEdit?: () => void
}

const Card3Columns: React.FC<ChildProps> = (props) => {
  const NoResultContent = () => {
    return (
      <h5 className='h5 textGrey500 textAlignCenter'>
        {props.noResultsText ? props.noResultsText : 'You have no results'}
      </h5>
    )
  }

  const RowContent = () => {
    return (
      <GridRow padding={false}>
        {props.data?.map(({ label, value }, key) => (
          <GridCol sm={label === 'Description' ? 6 : 3} key={key}>
            <p className='grey-700 label'>{label}</p>
            {value?.onRender ? (
              value.onRender()
            ) : (
              <p className='grey-100 body1-regular'>{value}</p>
            )}
          </GridCol>
        ))}
      </GridRow>
    )
  }

  return (
    <div className={props.customClassName}>
      {props.loading ? (
        <CardLoading
          label={props.header}
          num={props.skeletonRowsNumber}
          showCollapse={props.showCollapse}
          open={props.open}
        />
      ) : props.headerWithButtons ? (
        <Card
          headerContent={
            <div className='cardHeaderWithButton'>
              <div className='heading'>{props.header}</div>
              <Button
                onClick={() => props.onEdit()}
                text={props.headerButtonText ? props.headerButtonText : 'Edit'}
                variant='secondary'
              />
            </div>
          }
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={true}
          bodyContent={
            props?.data.length ? <RowContent /> : <NoResultContent />
          }
        />
      ) : (
        <Card
          headerContent={props.header}
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={true}
          bodyContent={
            props?.data.length ? <RowContent /> : <NoResultContent />
          }
        />
      )}
    </div>
  )
}

export default Card3Columns
