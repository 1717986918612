import API, { API_URL } from 'api'

export const getPlatformAccounts = async ({ params = {} }) => {
  return await API.get(API_URL.PLATFORM_ACCOUNTS(), { params })
}

export const createPlatformAccounts = async ({ body }) => {
  return await API.post(API_URL.PLATFORM_ACCOUNTS(), body)
}

export const updatePlatformAccounts = async ({ body, id }) => {
  return await API.patch(API_URL.PLATFORM_ACCOUNTS_DETAIL({ id }), body)
}
