import API, { API_URL } from 'api'

export const getTemplates = async ({ params = {} }) => {
  return await API.get(API_URL.GET_TEMPLATES(), { params })
}

export const getTemplateMappings = async ({ id, params = {} }) => {
  return await API.get(API_URL.GET_TEMPLATE_MAPPINGS({ id }), {
    params,
  })
}

export const getPlatformTemplates = async ({
  platformCode,
  platformId,
  params,
}) => {
  return await API.get(
    API_URL.GET_PLATFORM_TEMPLATES({ platformCode, platformId }),
    { params }
  )
}

export const analyzeHeaders = async ({ body }) => {
  return await API.post(API_URL.ANALYZE_HEADERS(), body)
}

export const createTemplate = async ({ body }) => {
  return await API.post(API_URL.GET_TEMPLATES(), body)
}

export const updateTemplate = async ({ id, body }) => {
  return await API.patch(API_URL.GET_TEMPLATE_BY_ID({ id }), body)
}

export const getTemplateById = async ({ id }) => {
  return await API.get(API_URL.GET_TEMPLATE_BY_ID({ id }))
}

export const createTemplateMapping = async ({ id, body }) => {
  return await API.post(API_URL.GET_TEMPLATE_MAPPINGS({ id }), body)
}

export const updateTemplateMapping = async ({ id, mappingId, body }) => {
  return await API.patch(
    API_URL.GET_TEMPLATE_MAPPINGS_BY_MAPPING_ID({ id, mappingId }),
    body
  )
}

export const deleteTemplateMapping = async ({ id, mappingId, body }) => {
  return await API.delete(
    API_URL.GET_TEMPLATE_MAPPINGS_BY_MAPPING_ID({ id, mappingId }),
    body
  )
}

export const cloneTemplate = async ({ id }) => {
  return await API.post(API_URL.CLONE_TEMPLATE_BY_ID({ id }))
}
