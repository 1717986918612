import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Modal from 'atoms/modal/modal'
import { patchProposal } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'
import SelectTemplates from 'molecules/selectDropdowns/selectTemplates'
import RadioGroup from 'atoms/input/radioGroup'
import Dropdown from 'atoms/dropdown/dropdown'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
  platformAccounts: any
}

const approveProposalOptions = [
  {
    label: 'No, just mark this proposal as approved',
    value: '0',
  },
  {
    label: 'Yes, publish products',
    value: '1',
  },
]

const ApproveProposalModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [approveProposalMethod, setApproveProposalMethod] = useState('0')
  const [selectedPlatformTemplate, setSelectedPlatformTemplate] =
    useState<any>()
  const [selectedPlatform, setSelectedPlatform] = useState<any>()

  const user = useSelector((state: RootState) => state.user)
  const showToast = useToast()

  const approveProposal = async () => {
    try {
      setLoading(true)
      if (
        'require_pricing_approval_on_proposals' in
        props.proposal.connection.options
      ) {
        await approveProducts()
      } else {
        await approveProducts()
        await approvePricing()
        await completeProposal()
      }
      showToast({
        label: `Your proposal, has been approved successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: `Error exporting the proposal, please try again!`,
        isDismissable: true,
        id: '1',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  const approveProducts = async () => {
    await patchProposal({
      id: props.proposal.id,
      body: {
        status: 'approved',
        export_platform_template_id: selectedPlatformTemplate
          ? selectedPlatformTemplate.id
          : null,
        export_platform_account_id: selectedPlatform
          ? selectedPlatform.id
          : null,
      },
    })
  }

  const approvePricing = async () => {
    await patchProposal({
      id: props.proposal.id,
      body: {
        status: 'pricing_approved',
        sign_off: user.user.first_name + ' ' + user.user.last_name,
      },
    })
  }

  const completeProposal = async () => {
    await patchProposal({
      id: props.proposal?.id,
      body: {
        status: 'completed',
      },
    })
  }

  const PlatformExportOptions = () => {
    return (
      <>
        <Dropdown
          required
          label='Platform'
          width='100%'
          value={selectedPlatform}
          onChange={(option: any) => {
            setSelectedPlatform(option)
          }}
          options={props.platformAccounts.map((el) => ({
            id: el.id,
            label: el.platform.code || el.platform.name,
          }))}
          placeholder='Select Platform'
          disabled={loading}
        />
        <div className='divider-4' />
        <SelectTemplates
          handleUpdate={(option) => setSelectedPlatformTemplate(option)}
          selectedPlatform={selectedPlatform}
          selectedTemplate={selectedPlatformTemplate}
          direction='export'
        />
      </>
    )
  }

  const isReady = useMemo(() => {
    if (approveProposalMethod === '0') return true
    if (
      approveProposalMethod === '1' &&
      selectedPlatform &&
      selectedPlatformTemplate
    )
      return true
  }, [approveProposalMethod, selectedPlatform, selectedPlatformTemplate])

  return (
    <>
      <Modal
        headerText='Finish review'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Cancel',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => approveProposal(),
            text: 'Finish Review',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading || !isReady,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <div className='body1-regular grey-600 mb-4'>
            Do you also want to publish the products on this proposal to an
            external platform?
          </div>
          <RadioGroup
            label=''
            name='approveProposalMethod'
            onChange={(e) => {
              setApproveProposalMethod(e.target.value)
            }}
            options={approveProposalOptions}
            defaultValue={approveProposalMethod}
          />
          <div className='divider-4' />
          {approveProposalMethod === '1' ? <PlatformExportOptions /> : null}
        </>
      </Modal>
    </>
  )
}

export default ApproveProposalModal
