import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProposalVariants, getProposalProductDetail } from 'api/proposals'
import { Box } from 'atoms'
import { PAGE_TYPE, VARIANTS } from 'lib/constants'
import PageHeaderComponent from 'molecules/PageHeader'
import RemoveProposalProductModal from '../components/modals/removeProposalProduct'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import Card1Column from 'molecules/cards/1column'
import Card3Columns from 'molecules/cards/3Columns'
import Image from 'atoms/image/image'
import ProposalItemsTable from '../components/table/items'
import { getTenantType } from 'api/helper'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { getTemplateMappings } from 'api/templates'
import BulkActionsBar from 'organisms/bulkActions'
import {
  setSelected,
  setSelectedProposalVariantIds,
} from 'store/proposalItemsSlice'
import Card4Columns from 'molecules/cards/4Columns'
import ProductMediaCard from '../components/presenters/mediaCards'

const ProposalProductDetail: React.FC = () => {
  const { id, productId } = useParams()
  const { isRetailer } = getTenantType()

  const [loading, setLoading] = useState(true)
  const [loadingVariants, setLoadingVariants] = useState(true)
  const [proposalProduct, setProposalProduct] = useState<any>({})
  const [tableData, setTableData] = useState<any[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [productImages, setProductImages] = useState<any[]>([])
  const [template, setTemplate] = useState<any>(null)
  const [loadingTemplate, setLoadingTemplate] = useState(true)

  const params = useSelector((state: RootState) => state.proposalItems.params)
  const selected = useSelector(
    (state: RootState) => state.proposalItems.selected
  )

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await loadProposalProduct()
    })()
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          isRetailer
            ? breadcrumbs.supplierProposalProductDetails({
                proposalID: id,
                productName: productId,
              })
            : breadcrumbs.proposalDetails()
        ),
      0
    )
  }, [])

  useEffect(() => {
    if (params) {
      ;(async () => {
        await fetchProposalVariants()
      })()
    }
  }, [params])

  const fetchProposalVariants = async (quiet?: boolean) => {
    if (!quiet) setLoadingVariants(true)
    dispatch(setSelected([]))
    dispatch(setSelectedProposalVariantIds([]))
    const { data } = await getProposalVariants({
      id,
      params: { product_id: productId, ...params },
    })
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoadingVariants(false)
  }

  useEffect(() => {
    if (proposalDetails?.department) {
      setLoadingTemplate(true)
      loadTemplateMappings()
    }
  }, [proposalDetails])

  const attributeGroups = useMemo(() => {
    const initialGroups = {
      description: [],
      general: [],
      images: [],
      inventory: [],
      misc: [],
      retailer: [],
      shipping: [],
      identifiers: [],
      pricing: [],
    }
    if (!loadingTemplate && !loading) {
      if (!template) return null
      else {
        template.results.forEach((mapping) => {
          if (mapping.target === 'product') {
            if (
              mapping.attribute.code in proposalProduct.attributes &&
              mapping.attribute.grouping in initialGroups
            ) {
              initialGroups[mapping.attribute.grouping].push({
                label: mapping.attribute.name,
                value:
                  proposalProduct.attributes[mapping.attribute.code].values[0]
                    .value,
              })
            }
          }
        })
      }
    }
    return initialGroups
  }, [loadingTemplate, template, loading, proposalProduct])

  const formatData = ({ media }) => {
    // let productImages = []

    // if (media) {
    //   media.forEach((medium) => {
    //     productImages.push({
    //       onRender: () => <Image alt='item' size='medium' src={medium.url} />,
    //     })
    //   })
    // }
    setProductImages(media)
  }

  const loadProposalProduct = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getProposalProductDetail({ id, productId })
    formatData(data)
    setProposalProduct(data)
    setLoading(false)
  }

  const loadTemplateMappings = async () => {
    const { data } = await getTemplateMappings({
      id: proposalDetails.department?.template?.id,
      params: {
        pagination: 0,
      },
    })
    setTemplate(data)
    setLoadingTemplate(false)
  }

  return (
    <>
      {/* Modals */}
      <RemoveProposalProductModal
        showModal={showRemoveModal}
        setShowModal={setShowRemoveModal}
        product={proposalProduct}
        loadProducts={() => null}
      />
      <Box padding={{ left: 6, right: 6, top: 4, bottom: 4 }}>
        <PageHeaderComponent
          loading={loading}
          h1Text={proposalProduct?.parent_name}
          h2Text={`Product #${proposalProduct?.parent_identifier}`}
          imageProps={{
            alt: '',
            size: 'medium',
            src: proposalProduct?.primary_media?.url,
          }}
          primaryButtons={[
            {
              onClick: () => setShowRemoveModal(true),
              text: 'Remove',
              variant: VARIANTS.DESTRUCTIVE,
            },
          ]}
        />
        <div className='divider-4' />
        <ProductMediaCard
          header={
            <Box flex={{ flexDirection: 'column' }}>
              <div className='card-header'>Product Media</div>
              <p className='body2-regular grey-600'>
                Photos may be resized for consistency. Please review the native
                dimensions listed below each photo (WxH).
              </p>
            </Box>
          }
          loading={loading}
          media={productImages}
        />
        <div className='divider-4' />
        <Card3Columns
          header='Product information'
          loading={loading}
          data={attributeGroups.description}
          customClassName='mb-4'
          showCollapse={false}
        />
        {attributeGroups.misc.length > 0 && (
          <>
            <Card3Columns
              header='Additional Attributes'
              loading={loading}
              data={attributeGroups.misc}
              customClassName='mb-4'
              showCollapse={false}
            />
          </>
        )}
        <p className='h5 mb-3'> Items and Costs</p>
        <BulkActionsBar
          loading={loading}
          hasBulkActions={true}
          selectedItems={selected}
          totalRows={totalRows}
          currentPage={params.page}
          pageType={PAGE_TYPE.PROPOSAL_ITEMS}
          options={{
            status: proposalDetails?.status,
            proposalId: proposalDetails?.id,
            issueSummary: proposalDetails?.issues_summary,
          }}
          loadProducts={fetchProposalVariants}
        />
        <ProposalItemsTable
          data={tableData}
          loading={loadingVariants}
          totalRows={totalRows}
          currentPage={params.page}
          currentSortBy={params.order_by}
          selected={selected}
          proposal={proposalDetails}
          isRetailer={isRetailer}
          params={params}
          isProductDetail
          loadProducts={fetchProposalVariants}
          // not needed
          loadProposal={null}
        />
      </Box>
    </>
  )
}

export default ProposalProductDetail
