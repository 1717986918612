import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps, useToast } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentPage as setProposalItemsCurrentPage,
  setSelected as setSelectedProposalItems,
  setSelectedProposalVariantIds,
} from 'store/proposalItemsSlice'
import VariantItem from 'molecules/lineItem/variantItem'
import ItemStatusPresenter from '../presenters/itemStatus'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import { getPercentage } from 'lib/utils/math'
import { Badge, GridCol, GridRow, Link, Modal } from 'atoms'
import { RootState } from 'store'
import ProductMediaCard from '../presenters/mediaCards'
import CartonDetails from '../presenters/cartonDetails'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  selected: string[]
  proposal: any
  isRetailer: boolean
  isProductDetail?: boolean
  params: any
  loadProposal: (quiet?: boolean) => void
  loadProducts: (quiet?: boolean) => void
}

const ProposalItemsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const setSelected = setSelectedProposalItems
  const setCurrentPage = setProposalItemsCurrentPage

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [mediaData, setMediaData] = useState([])

  const variantSelected = useSelector(
    (state: RootState) => state.proposalItems.selectedProposalVariantIDs
  )

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.selected])

  const getRetailerCost = (row) => {
    let retailerCost = row.proposal_variant.pricing.retailer_cost.values[0]
    if (row.proposal_variant.pricing.retailer_cost.values.length > 1) {
      const values = row.proposal_variant.pricing.retailer_cost.values
      // fetch the last created value
      retailerCost = values.reduce((a, b) =>
        a.created_at > b.created_at ? a : b
      )
    }
    return formatCurrency(retailerCost?.value)
  }

  const getMargin = (pricing) => {
    function getValue(key) {
      let value = pricing[key].values[0].value
      if (pricing[key].values.length > 1) {
        const values = pricing[key].values
        // fetch the last created value
        value = values.reduce((a, b) =>
          a.created_at > b.created_at ? a : b
        ).value
      }
      return value
    }

    let cost = null
    let price = null

    if ('retailer_cost' in pricing) {
      cost = parseFloat(getValue('retailer_cost'))
    }
    if ('retailer_price' in pricing) {
      price = parseFloat(getValue('retailer_price'))
    }
    if (cost && price) {
      return getPercentage((price - cost) / price)
    } else return '--'
  }

  const tableColumns = [
    {
      title: 'Item',
      defaultVisibility: true,
    },
    {
      title: 'Cost',
      minWidth: 100,
    },
    {
      title: 'Sugg. retail',
      minWidth: 100,
    },
    {
      title: 'Est. margin',
      minWidth: 100,
    },
    {
      title: 'Status',
      minWidth: 100,
    },
    {
      title: 'Product SKU',
      minWidth: 120,
    },
    {
      title: 'Merchant SKU',
      minWidth: 120,
    },
    {
      title: 'Images',
      minWidth: 100,
    },
    {
      title: '# Cartons',
      minWidth: 100,
    },
    {
      title: 'Measurements',
      minWidth: 180,
    },
    {
      title: 'Issues',
      minWidth: 100,
    },
  ]

  const isSelected = (id: string) => {
    return props?.selected?.includes(id)
  }

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((item) => {
      return {
        id: item?.id,
        proposalVariantId: item?.proposal_variant?.id,
        selected: isSelected(item?.id),
        data: {
          'Item': {
            onRender: () =>
              VariantItem({
                variant: item,
                showVariantDetails: true,
                showAdditionalActions: true,
                loadProducts: props.loadProducts,
                loadProposal: props.loadProposal,
              }),
          },
          'Cost': {
            value: item?.proposal_variant?.pricing?.retailer_cost
              ? getRetailerCost(item)
              : 'Not Set',
          },
          'Sugg. retail': {
            value: item?.proposal_variant?.pricing?.retailer_price
              ? formatCurrency(
                  item?.proposal_variant?.pricing?.retailer_price?.values[0]
                    ?.value
                )
              : 'Not Set',
          },
          'Est. margin': { value: getMargin(item?.proposal_variant?.pricing) },
          'Status': {
            onRender: () => <ItemStatusPresenter item={item} />,
          },
          'Product SKU': {
            value: item?.attributes?.parent_identifier
              ? item?.attributes.parent_identifier?.values[0]?.value
              : '--',
          },
          'Merchant SKU': {
            value:
              item?.retailer_identifiers.length > 0
                ? item?.retailer_identifiers[0]?.identifier
                : 'New SKU',
          },
          'Images': {
            onRender: () =>
              item.media?.length > 0 ? (
                <Link
                  label={'View'}
                  variant='primary'
                  onClick={() => {
                    setShowMediaModal(true)
                    setMediaData(item?.media)
                  }}
                  mode='inline'
                />
              ) : (
                '--'
              ),
          },
          '# Cartons': {
            value: item?.cartons.length > 0 ? item.cartons.length : '--',
          },
          'Measurements':
            item?.cartons.length > 0
              ? {
                  onRender: () => (
                    <CartonDetails dimensions={item?.cartons?.[0]} />
                  ),
                }
              : { value: '--' },
          'Issues': !item?.proposal_variant?.issues.length
            ? {
                onRender: () => (
                  <Badge label='No open Issues' status={'primary'} />
                ),
              }
            : { value: item?.proposal_variant.issues.length },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  //TODO: create custom hooks for selecting rows to handle this centrally?
  const handleSingleRowSelect = (e, rowDetails: any, selected: boolean) => {
    if (selected) {
      dispatch(setSelected([rowDetails.id, ...props.selected]))
      dispatch(
        setSelectedProposalVariantIds([
          rowDetails.proposalVariantId,
          ...props.selected,
        ])
      )
    } else {
      const newSelectedItemsIds = props.selected.filter(
        (selectedItemId) => selectedItemId !== rowDetails.id
      )
      const newSelectedProposalVariantIds = variantSelected.filter(
        (selectedVariantId) =>
          selectedVariantId !== rowDetails.proposalVariantId
      )
      dispatch(setSelected(newSelectedItemsIds))
      dispatch(setSelectedProposalVariantIds(newSelectedProposalVariantIds))
    }
  }

  const handleAllRowSelect = (e, allRowsSelected: boolean) => {
    if (!allRowsSelected) {
      dispatch(setSelected([]))
      dispatch(setSelectedProposalVariantIds([]))
    } else {
      const allItemIds = tableData.map((item) => item.id)
      const allVariantIds = props.data.map((item) => item.proposal_variant.id)
      dispatch(setSelected(allItemIds))
      dispatch(setSelectedProposalVariantIds(allVariantIds))
    }
  }

  return (
    <>
      {/* media modal */}
      <Modal
        headerText='Item Images'
        onClose={() => setShowMediaModal(false)}
        isVisible={showMediaModal}
        footerButtons={[
          {
            onClick: () => setShowMediaModal(false),
            text: 'Close',
            variant: 'tertiary',
          },
        ]}
        onBackdropClick={() => setShowMediaModal(false)}
      >
        <>
          <ProductMediaCard media={mediaData} onlyMedia />
        </>
      </Modal>
      <GridRow padding={false}>
        <GridCol>
          <Table
            columnVisibilityFilter
            columns={tableColumns}
            data={tableData}
            loading={props.loading}
            selectable={true}
            onRowSelect={(e, rowDetails, selected) =>
              handleSingleRowSelect(e, rowDetails, selected)
            }
            onAllRowSelect={(e, allRowsSelected) =>
              handleAllRowSelect(e, allRowsSelected)
            }
            customPaginationProps={{
              handlePagination: (pageNumber: number) => {
                setPage(pageNumber)
              },
              activePageNumber: props.currentPage,
              perPage: 10,
              totalRecords: props.totalRows,
            }}
          />
        </GridCol>
      </GridRow>
    </>
  )
}

export default ProposalItemsTable
