import { FC, useEffect } from 'react'
import { SETTING_OPTIONS } from './constants'
import { PageHeader, GridCol, GridRow, Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import SelectableCard from 'atoms/card/selectableCard'
import { useNavigate } from 'react-router-dom'
import PageHeaderComponent from 'molecules/PageHeader'

const SettingsDashboardTemplate: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () => window.updateBreadcrumb(breadcrumbs.settingsDashboard()),
      0
    )
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeaderComponent h1Text='Settings' customClassName='mb-4' />
        </GridCol>
      </GridRow>
      <GridRow>
        {SETTING_OPTIONS.map((el) => {
          return (
            <GridCol lg={6} md={6} sm={12}>
              <SelectableCard
                text={el.Title}
                iconName={el.iconName}
                description={el.Description}
                orientation='horizontal'
                onChange={() => navigate(el.path)}
              />
            </GridCol>
          )
        })}
      </GridRow>
    </Box>
  )
}

export default SettingsDashboardTemplate
