import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { formatDate } from 'lib/utils/date/formatDate'
import GridLabelValuePair from 'molecules/modal/gridLabelValuePair'
import ProposalIssueStatusPresenter from '../../presenters/issueStatus'
import HorizontalTab from 'atoms/tab'
import IssueItemsTable from './itemsTable'
import IssueNotesTable from './notesTable'
import { useToast } from '@teamfabric/copilot-ui'
import ArchiveIssueModal from './archiveIssue'
import CloseIssueModal from './closeIssue'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  issue: any
  loadProposal?: (quiet?: boolean) => void
  isRetailer?: boolean
  loadProposalIssue: () => void
}

const InspectIssueModal: React.FC<ChildProps> = (props) => {
  const { issue, proposal, isRetailer, loadProposalIssue } = props
  const [label, setLabel] = useState(0)
  const [showCloseIssueModal, setShowCloseIssueModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const row1Data = [
    {
      label: 'Title',
      value: issue?.title,
    },
    {
      label: 'Status',
      value: <ProposalIssueStatusPresenter issue={props.issue} />,
    },
  ]

  const row2Data = [
    {
      label: 'Created By',
      value: issue?.created_by?.first_name + ' ' + issue?.created_by?.last_name,
    },
    {
      label: 'Created Date',
      value: formatDate({ date: issue?.created_at }),
    },
  ]

  const row3Data = [
    {
      label: 'Description',
      value: issue?.message,
    },
  ]

  const issueIsClosed = issue?.is_closed && !issue?.is_archived
  const issueIsArchived = issue?.is_archived

  const inspectIssueFooterButtons = useMemo(() => {
    const buttons = [
      {
        onClick: () => props.setShowModal(false),
        text: 'Cancel',
        variant: 'tertiary',
      },
    ]

    if (!issueIsArchived) {
      buttons.push({
        onClick: () =>
          issueIsClosed
            ? setShowArchiveModal(true)
            : setShowCloseIssueModal(true),
        text: issueIsClosed ? 'Archive Issue' : 'Mark Issue Closed',
        variant: 'primary',
      })
    }

    return buttons
  }, [issueIsArchived, issueIsClosed])

  const closeModals = () => {
    props.setShowModal(false)
    setShowCloseIssueModal(false)
    setShowArchiveModal(false)
  }

  return (
    <>
      <Modal
        headerText='Inspect Issue'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={inspectIssueFooterButtons}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <GridLabelValuePair
            defaultColSize={6}
            data={row1Data}
            customClassName='mb-4'
          />
          <GridLabelValuePair
            defaultColSize={6}
            data={row2Data}
            customClassName='mb-4'
          />
          <GridLabelValuePair defaultColSize={12} data={row3Data} />
          <div className='divider-4' />
          <HorizontalTab
            onTabChange={setLabel}
            data={{
              tabs: [
                {
                  label: `Items (${issue?.elements?.length})`,
                  content: (
                    <IssueItemsTable
                      data={issue.elements}
                      proposal={proposal}
                      isRetailer={isRetailer}
                    />
                  ),
                },
                {
                  label: `Notes (${issue?.memos?.length})`,
                  content: (
                    <IssueNotesTable
                      data={issue}
                      proposal={proposal}
                      isRetailer={isRetailer}
                    />
                  ),
                },
              ],
            }}
          />
        </>
      </Modal>
      {/* close issue modal */}
      <CloseIssueModal
        showModal={showCloseIssueModal}
        onComplete={closeModals}
        setShowModal={setShowCloseIssueModal}
        proposal={proposal}
        issue={issue}
        loadProposalIssue={loadProposalIssue}
      />
      {/* Archive modal */}
      <ArchiveIssueModal
        showModal={showArchiveModal}
        onComplete={closeModals}
        setShowModal={setShowArchiveModal}
        proposal={proposal}
        issue={issue}
        loadProposalIssue={loadProposalIssue}
      />
    </>
  )
}

export default InspectIssueModal
