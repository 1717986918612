import React from 'react'
import { FilterModal as FilterModalUI } from '@teamfabric/copilot-ui'

type FilterModalProps = {
  filters: any
  onApply: () => void
  onCancel: () => void
  onResetAll: () => void
  primaryButtonText: string
}

const FilterModal: React.FC<FilterModalProps> = ({ ...props }) => {
  return <FilterModalUI {...props} />
}

export default FilterModal
