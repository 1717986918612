import React, { useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import ProductFilters from '../filters/products'
import SearchBar from 'molecules/search/searchBarTable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import BulkActionsBar from 'organisms/bulkActions'
import { PAGE_TYPE, SEARCH } from 'lib/constants'
import { getProposalProducts } from 'api/proposals'
import { setSelected, setSearch } from 'store/proposalProductsSlice'
import ProposalProductsTable from '../table/products'
import { useParams } from 'react-router-dom'
import { Box } from 'atoms'

type ProposalProductsProps = {
  proposal: any
  isRetailer: boolean
  loadProposal: (quiet?: boolean) => void
}

const ProposalProducts: React.FC<ProposalProductsProps> = ({
  proposal,
  loadProposal,
}) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const { id, productId } = useParams()
  const params = useSelector(
    (state: RootState) => state.proposalProducts.params
  )
  const selected = useSelector(
    (state: RootState) => state.proposalProducts.selected
  )

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await fetchProposalProducts()
    })()
  }, [params])

  const fetchProposalProducts = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    dispatch(setSelected([]))
    const { data: data } = await getProposalProducts({
      id: id,
      params: { ...params },
    })
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoading(false)
  }

  return (
    <>
      <Box width='100%' padding={{ top: 5, bottom: 4 }}>
        <SearchBar
          placeholder={'Search by Product name or Product ID'}
          setSearch={setSearch}
          className='mb-4'
        />
        <ProductFilters params={params} isProduct />
        <BulkActionsBar
          loading={loading}
          hasBulkActions={true}
          selectedItems={selected}
          totalRows={totalRows}
          currentPage={params.page}
          pageType={PAGE_TYPE.PROPOSAL_PRODUCTS}
          options={{
            status: proposal?.status,
            proposalId: proposal?.id,
            issueSummary: proposal?.issues_summary,
          }}
          reload={loadProposal}
          loadProducts={fetchProposalProducts}
        />
        <ProposalProductsTable
          data={tableData}
          loading={loading}
          totalRows={totalRows}
          currentPage={params.page}
          currentSortBy={params.order_by}
          selected={selected}
          proposal={proposal}
          options={{
            status: proposal?.status,
            proposalId: proposal?.id,
            issueSummary: proposal?.issues_summary,
          }}
          loadProposal={loadProposal}
          loadProducts={fetchProposalProducts}
        />
      </Box>
    </>
  )
}

export default ProposalProducts
