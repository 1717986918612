import { Box as BoxUI } from '@teamfabric/copilot-ui'
import React from 'react'

type BoxProps = {
  dataTestid?: string
  onClick?: () => void
  padding?: any
  as?: any
  gap?: number
  flex?: any
  height?: string
  width?: string
  margin?: any
  border?: any
  backgroundColor?: any
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  typography?: any
}

const Box: React.FC<BoxProps> = ({ ...props }) => {
  return <BoxUI {...props}></BoxUI>
}

export default Box
