import InteractiveIcon from 'atoms/interactiveIcon/interactiveIcon'
import ToolTip from 'atoms/tooltip/tooltip'

type ChildProps = {
  handleClick: (key: string) => void
  hideEdit?: boolean
}

const EditDeleteTableRow: React.FC<ChildProps> = ({
  hideEdit,
  handleClick,
}) => {
  return (
    <div className='show-on-row-hover table-action-row edit-table-row'>
      {hideEdit ? null : (
        <ToolTip placement='top' text='Edit' theme='dark' showArrow>
          <InteractiveIcon
            iconName='Pencil'
            onClick={() => handleClick('Edit')}
          />
        </ToolTip>
      )}
      <ToolTip placement='top' text='Delete' theme='dark' showArrow>
        <InteractiveIcon
          iconName='Trash'
          onClick={() => handleClick('Delete')}
        />
      </ToolTip>
    </div>
  )
}

export default EditDeleteTableRow
