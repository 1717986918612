import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSorting } from 'store/merchantSlice'
import { SORTING_MAPS } from '../constants'
import Badge from 'atoms/badge/badge'
import Link from 'atoms/link/link'
import { useNavigate } from 'react-router-dom'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const MerchantTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = [
    {
      sortable: true,
      title: 'ID',
      width: 120,
    },
    {
      sortable: true,
      title: 'Merchant',
    },
    {
      sortable: true,
      title: 'Supplier #',
    },
    {
      sortable: true,
      title: '# Orders (Last 7)',
    },
    {
      title: 'Integration',
    },
    {
      sortable: true,
      title: 'Status',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      ({
        id,
        retailer,
        brand,
        orders_last_7_days_count,
        integration_type,
        status,
      }) => {
        return {
          id,
          data: {
            'ID': { value: id },
            'Merchant': {
              onRender: () => (
                <Link
                  label={retailer.name}
                  variant='primary'
                  onClick={() => navigate(`/merchants/${id}`)}
                  mode='inline'
                />
              ),
            },
            'Supplier #': { value: brand?.name },
            '# Orders (Last 7)': { value: orders_last_7_days_count },
            'Integration': { value: integration_type },
            'Status': {
              onRender: () => (
                <Badge
                  label={status}
                  status={status === 'active' ? 'success' : 'alert'}
                />
              ),
            },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const handleSort = (key: string) => {
    const mappedKey = SORTING_MAPS.MERCHANTS[key]
    let newSortBy = ''
    // if sorting by the current sorting key, change sorting direction, otherwise sort descending by new key
    if (mappedKey.replace('-', '') == props.currentSortBy.replace('-', '')) {
      newSortBy =
        props.currentSortBy.charAt(0) == '-'
          ? props.currentSortBy.slice(1)
          : `-${props.currentSortBy}`
    } else {
      newSortBy = `-${mappedKey}`
    }
    dispatch(setSorting(newSortBy))
    dispatch(setCurrentPage(1))
  }

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      loading={props.loading}
      selectable={true}
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setPage(pageNumber)
        },
        activePageNumber: props.currentPage,
        perPage: 10,
        totalRecords: props.totalRows,
      }}
      onAscendingSort={(key: string) => handleSort(key)}
      onDescendingSort={(key: string) => handleSort(key)}
    />
  )
}

export default MerchantTable
