import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchBar from 'molecules/search/searchBarTable'
import { setSearch } from 'store/feedRequestSlice'
import { PageHeader } from 'atoms'
import ImportRequestTable from '../components/table/importRequestTable'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import { GridCol, GridRow, Box } from 'atoms'
import { PATHS } from 'routes/pageRoutes'

const ImportRequestList: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.IMPORT_REQUESTS)
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeader
            h1Text='Import requests'
            primaryButtons={[
              {
                onClick: () => navigate(PATHS.ImportProduct),
                icon: 'Add',
                text: 'Import Request',
                variant: 'primary',
              },
            ]}
          />
          <SearchBar
            placeholder='Search by order ID'
            setSearch={setSearch}
            className='mt-3 mb-3'
          />
          <ImportRequestTable />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default ImportRequestList
