import React from 'react'
import { Table as TableUI } from '@teamfabric/copilot-ui'

export type TableProps = {
  columns: any
  data: any
  loading?: boolean
  activePage?: number
  selectable?: boolean
  onRowSelect?: (e: any, rowDetails: any, selected: any) => void
  onAllRowSelect?: (e, allRowsSelected: boolean) => void
  totalRecords?: number
  perPageRecords?: number
  onAscendingSort?: (key: string) => void
  onDescendingSort?: (key: string) => void
  customPaginationProps?: any
  emptyTableText?: string
  onPageChange?: (num: number) => void
  hideCaption?: boolean
  showPagination?: boolean
  bulkActions?: any
  onClear?: any
  tableCaption?: string
  canHaveChildren?: boolean
  columnVisibilityFilter?: boolean
  onRowClick?: (e: any, rowDetails: any) => void
  onRowCollapse?: (e: any, rowDetails: any) => void
  onRowExpand?: (e: any, rowDetails: any) => void
  onRowMouseEnter?: (e: any, rowDetails: any) => void
  onRowMouseLeave?: (e: any, rowDetails: any) => void
  disableTableBorder?: boolean
}

const Table: React.FC<TableProps> = ({ ...props }) => {
  return (
    <TableUI
      {...props}
      showPagination={
        props.showPagination || props.customPaginationProps?.totalRecords > 10
      }
      totalRecords={
        props.totalRecords || props.customPaginationProps?.totalRecords
      }
      activePage={
        props.activePage || props.customPaginationProps?.activePageNumber
      }
      perPageRecords={props.perPageRecords || 10}
      emptyTableProps={{
        dataTestid: 'empty-table',
        headerText: '',
        description: props.emptyTableText ? props.emptyTableText : 'No Data',
      }}
    />
  )
}

export default Table
