import React, { useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import ProposalItemFilters from '../filters/items'
import SearchBar from 'molecules/search/searchBarTable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { PAGE_TYPE, SEARCH } from 'lib/constants'
import { getProposalVariants } from 'api/proposals'
import {
  setSelected,
  setSearch,
  setSelectedProposalVariantIds,
} from 'store/proposalItemsSlice'
import ProposalItemsTable from '../table/items'
import BulkActionsBar from 'organisms/bulkActions'
import { useParams } from 'react-router'

type ProposalItemsProps = {
  proposal: any
  isRetailer: boolean
  loadProposal: (quiet?: boolean) => void
}

const ProposalItems: React.FC<ProposalItemsProps> = ({
  proposal,
  isRetailer,
  loadProposal,
}) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  const params = useSelector((state: RootState) => state.proposalItems.params)
  const selected = useSelector(
    (state: RootState) => state.proposalItems.selected
  )

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    if (proposal?.id && params) {
      ;(async () => {
        await fetchProposalVariants()
      })()
    }
  }, [proposal, params])

  const fetchProposalVariants = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    dispatch(setSelected([]))
    dispatch(setSelectedProposalVariantIds([]))
    const { data: data } = await getProposalVariants({
      id: id,
      params: { ...params },
    })
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoading(false)
  }

  return (
    <>
      <SearchBar
        placeholder='Search by Item name or Supplier SKU'
        setSearch={setSearch}
        className='mt-4'
      />
      <ProposalItemFilters params={params} />
      <BulkActionsBar
        loading={loading}
        hasBulkActions={true}
        selectedItems={selected}
        totalRows={totalRows}
        currentPage={params.page}
        pageType={PAGE_TYPE.PROPOSAL_ITEMS}
        options={{
          status: proposal?.status,
          proposalId: proposal?.id,
          issueSummary: proposal?.issues_summary,
        }}
        reload={loadProposal}
        loadProducts={fetchProposalVariants}
      />
      <ProposalItemsTable
        data={tableData}
        loading={loading}
        totalRows={totalRows}
        currentPage={params.page}
        currentSortBy={params.order_by}
        selected={selected}
        proposal={proposal}
        isRetailer={isRetailer}
        params={params}
        loadProposal={loadProposal}
        loadProducts={fetchProposalVariants}
      />
    </>
  )
}

export default ProposalItems
