import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { DataProps, useToast } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { Box, Button, MultiLineTextArea } from 'atoms'
import Card from 'atoms/card/card'
import { formatDate } from 'lib/utils/date/formatDate'
import SingleAvatar from 'atoms/avatar/singleAvater'
import { postIssueMemo } from 'api/memo'
import { getProposalIssueDetails } from 'api/proposals'

type ChildProps = {
  data: any
  proposal: any
  isRetailer: boolean
  closeIssueModal?: Dispatch<SetStateAction<boolean>>
}

const IssueNotesTable: FC<ChildProps> = (props) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [memoText, setMemoText] = useState(undefined)
  const [saving, setSaving] = useState(false)
  const showToast = useToast()
  const [memos, setMemos] = useState(props.data.memos)

  const fetchIssueDetails = async () => {
    const { data } = await getProposalIssueDetails({
      proposalId: props.proposal.id,
      issueId: props.data?.id,
    })
    setMemos(data.memos)
  }

  useEffect(() => {
    formatSetTableData()
  }, [memos])

  const tableColumns = [
    {
      title: 'Created',
      width: 200,
    },
    {
      title: 'Note',
    },
  ]

  const formatSetTableData = () => {
    const formattedData = memos?.map((memo) => {
      return {
        id: memo?.id,
        data: {
          Created: {
            onRender: () => (
              <div className='flex-start'>
                <SingleAvatar
                  size={32}
                  label={`${memo?.created_by.first_name} ${memo?.created_by.last_name}`}
                  isLabelVisible={false}
                />
                <div className='ml-3'>
                  <p className='body1-regular grey-1000'>
                    {memo?.created_by?.first_name} {memo?.created_by?.last_name}
                  </p>
                  <p className='grey-700 body2-regular'>
                    {formatDate({ date: memo?.created_at })}
                  </p>
                </div>
              </div>
            ),
          },
          Note: {
            value: memo?.text ? memo?.text : 'Not Set',
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const addMemo = async () => {
    try {
      setSaving(true)
      const { data } = await postIssueMemo({
        text: memoText,
        module: 'proposals',
        moduleId: props.proposal.id,
        issueId: props.data.id,
      })
      if (data?.id) {
        fetchIssueDetails()
      }
      showToast({
        label: 'Note added successfully!',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error adding the note: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
      setMemoText('')
    }
  }

  return (
    <>
      <Box width='100%' padding={{ top: 4, bottom: 4 }}>
        <Card
          headerContent={
            <div className='pb-0'>
              <p className='body2-regular grey-600'>
                Notes that can be seen by all parties
              </p>
            </div>
          }
          bodyContent={
            <>
              <div className='space-between gap-4 mb-5'>
                <MultiLineTextArea
                  label=''
                  width='100%'
                  inputProps={{
                    value: memoText,
                    onChange: (e) => {
                      setMemoText(e.target.value)
                    },
                    placeholder: 'Add a note for your trading partner',
                  }}
                />
                <div className='v-center'>
                  <Button
                    text='Post'
                    onClick={() => addMemo()}
                    isDisabled={!memoText}
                    isLoading={saving}
                  />
                </div>
              </div>
              {tableData.length > 0 ? (
                <Table columns={tableColumns} data={tableData} />
              ) : (
                <h5 className='grey-500 textAlignCenter'>
                  No notes have been posted
                </h5>
              )}
            </>
          }
          showCollapse={false}
        />
      </Box>
    </>
  )
}

export default IssueNotesTable
