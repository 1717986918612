import React, { useState, useMemo, useEffect } from 'react'
import { Box, Input, InputStepper, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { patchRuleValidator, putRuleValidator } from 'api/rulesets'
import { useParams } from 'react-router-dom'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import SelectRuleValidatorType from 'molecules/selectDropdowns/selectRuleValidatorType'
import MultiSelect from 'atoms/select/multiSelect'

type ChildProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  reload: (quiet?: boolean) => void
  type: 'Create' | 'Edit'
  rules: any[]
  selectedRow: any
}

const convertValueToObjects = (value: string) => {
  return value.split(',').map((item) => {
    const trimmedItem = item.trim()
    return { id: trimmedItem, label: trimmedItem }
  })
}

const RuleValidatorModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  reload,
  selectedRow,
  type,
  rules,
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  // default value is set to 'In'
  const [selectedType, setSelectedType] = useState(dropDownInitialObj)
  const [value, setValue] = useState('')
  const [multiValues, setMultiValues] = useState([])

  const intType = ['max_length', 'min_length', 'max_value', 'min_value']
  const booleanType = [
    'integer_only',
    'numeric_only',
    'alpha_only',
    'alphanumeric_only',
  ]
  const multiValuesTypes = ['in', 'not_in', 'allowed_characters']
  const valueTypes = ['exact']

  const onSave = async () => {
    const body = {
      rule_validators: [
        {
          type: selectedType?.id,
          ...(multiValuesTypes.includes(selectedType?.id)
            ? { values: [multiValues.map((el) => el.label).join(',')] }
            : { value: value }),
        },
      ],
    }
    try {
      setSaving(true)
      if (type === 'Create') {
        await patchRuleValidator({ id: id, ruleId: selectedRow?.id, body })
      } else {
        await putRuleValidator({ id: id, ruleId: selectedRow?.id, body })
      }
      setShowModal(false)
      reload()
      showToast({
        label: 'Rule validation added successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error : ${errorMessage}`
          : 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
      resetForm()
    }
  }

  const onDelete = async () => {
    try {
      const body = {
        rule_validators: [],
      }
      await putRuleValidator({ id: id, ruleId: selectedRow.id, body })
      showToast({
        label: 'Rule validation deleted successfully.',
        isDismissable: true,
        id: '1',
      })
    } finally {
      reload()
      resetForm()
      setShowModal(false)
    }
  }

  const isReady = useMemo(() => {
    return selectedType?.id
  }, [selectedType])

  const resetForm = () => {
    setValue('')
    setMultiValues([])
    setSelectedType(dropDownInitialObj)
  }

  console.log('=====multiValues', multiValues)

  useEffect(() => {
    if (selectedRow) {
      if (selectedRow?.rule_validators?.[0]?.type) {
        const ruleValidator = selectedRow?.rule_validators?.[0]
        setSelectedType({
          id: ruleValidator?.type,
          label: ruleValidator?.type,
        })
        if (multiValuesTypes.includes(ruleValidator?.type)) {
          const values = convertValueToObjects(ruleValidator?.values?.[0])
          setMultiValues(values)
        } else {
          setValue(ruleValidator?.value)
        }
      }
    }
  }, [selectedRow, type])

  const NoInput = () => null

  const MultiSelectInput = () => {
    return (
      <MultiSelect
        label={'Add Value'}
        name='values'
        width='100%'
        onChange={(val) => setMultiValues(val)}
        onClear={async () => {}}
        options={multiValues}
        hideOptionList={true}
      />
    )
  }

  const TextValueInput = () => {
    return (
      <Input
        label='Value'
        width='100%'
        required
        inputProps={{
          value: value,
          onChange: ({ target: { value } }) => setValue(value),
        }}
      />
    )
  }

  const NumberInput = () => {
    return (
      <InputStepper
        label='Value'
        width='100%'
        required
        max={100}
        min={1}
        onValueChange={(newVal) => {
          const parsedValue =
            typeof newVal === 'string' ? newVal : newVal.toString()
          setValue(parsedValue)
        }}
        stepSize={1}
        value={parseInt(value)}
      />
    )
  }

  const InputValueComponent = useMemo(() => {
    if (intType.includes(selectedType?.id)) {
      return NumberInput
    } else if (booleanType.includes(selectedType?.id)) {
      return NoInput
    } else if (multiValuesTypes.includes(selectedType?.id)) {
      return MultiSelectInput
    } else if (valueTypes.includes(selectedType?.id)) {
      return TextValueInput
    } else {
      return null
    }
  }, [selectedType])

  return (
    <Modal
      headerText={'Rule Validators'}
      description=''
      size='small'
      onClose={() => {
        resetForm()
        setShowModal(false)
      }}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => {
            resetForm()
            setShowModal(false)
          },
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        type === 'Edit' && {
          dataTestid: 'delete-modal-button',
          onClick: () => onDelete(),
          text: 'Delete',
          variant: 'destructive',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onSave(),
          text: 'Save',
          variant: 'primary',
          isDisabled: !isReady || saving,
        },
      ].filter(Boolean)}
      dataTestid={''}
      onBackdropClick={() => {
        resetForm()
        setShowModal(false)
      }}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <Input
          width='100%'
          label='Name'
          required
          inputProps={{
            value: selectedRow?.name,
            disabled: true,
          }}
        />
        <SelectRuleValidatorType
          handleUpdate={(option) => {
            setSelectedType(option)
          }}
          fieldLabel='Select type'
          validatorType={selectedRow?.rule_validators?.[0]?.type}
        />
        {InputValueComponent ? <InputValueComponent /> : null}
      </Box>
    </Modal>
  )
}

export default RuleValidatorModal
