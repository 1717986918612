import React, { ReactNode } from 'react'
import { Search as SearchUI, SearchVariant } from '@teamfabric/copilot-ui'

type SeachProps = {
  placeholder?: string
  onSearch?: (searchText: string) => void
  onClear?: () => void
  onChange?: (searchText: string) => void
  results?: React.ReactNode
  variant?: SearchVariant
  showResult?: boolean
  width?: string
  showResultOnFocus?: boolean
  advancedSearch?: ReactNode
  value?: string
  onMouseEnter?: any
}

const Search: React.FC<SeachProps> = ({ ...props }) => {
  return (
    <SearchUI
      {...props}
      variant={props.variant ? props.variant : SearchVariant.PAGE}
    />
  )
}

export default Search
