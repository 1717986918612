import React, { useEffect, useMemo, useState } from 'react'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import { formatRule } from 'modules/proposals/utils'
import { capitalize } from 'lodash'

type InspectItemTabContentProps = {
  itemAttributesInfo: any
  variantAttributes: any
  ruleset: any
  loading: boolean
}

const InspectItemTabContent: React.FC<InspectItemTabContentProps> = ({
  itemAttributesInfo,
  variantAttributes,
  ruleset,
  loading,
}) => {
  const [rulesetResults, setRulesetResults] = useState({})

  useEffect(() => {
    const rulesetResults = getRulesetResults()
    setRulesetResults(rulesetResults)
  }, [ruleset])

  const getRulesetResults = () => {
    const results = {}
    ruleset?.results?.forEach((result) => {
      results[result.rule.attribute.code] = result
    })
    return results
  }

  const renderRulesetGroups = () => {
    return ruleset?.ruleset?.rule_groups?.map((group) => {
      let formattedRulesData = []
      group.rules.forEach((rule) => {
        formattedRulesData.push(
          formatRule({
            key: rule.id,
            rule: rule,
            attribute:
              rule.attribute.code in variantAttributes
                ? variantAttributes[rule.attribute.code]
                : null,
            result:
              rule.attribute.code in rulesetResults
                ? rulesetResults[rule.attribute.code]
                : null,
          })
        )
      })

      return (
        <div key={group.id} className='mt-4'>
          <ModalCard2Col
            header={capitalize(group.name)}
            open={false}
            showCollapse={true}
            data={formattedRulesData}
            loading={loading}
            addGap
          />
        </div>
      )
    })
  }

  return (
    <div className='mt-4'>
      <ModalCard2Col
        header='Proposal Item Attributes'
        open={true}
        showCollapse={true}
        data={itemAttributesInfo}
        loading={loading}
        addGap
      />
      {renderRulesetGroups()}
    </div>
  )
}

export default InspectItemTabContent
