import React from 'react'
import Filter from 'atoms/filter/filter'
import FilterSidebar from 'atoms/filter/filterSidebar'
import { FilterSideBarVariant } from '@teamfabric/copilot-ui'
import { SearchVariant } from '@teamfabric/copilot-ui'
import { getConnections } from 'api/connection'
import SearchBarServerSide from 'molecules/search/searchBarServerSide'
import { FILTERS } from '../constants'
import { PRODUCTS } from 'modules/products/constants'

type FilterProps = {
  dataTestid: string
  label: string
  loading: boolean
  onClear: () => void
  onChange: (id: string) => void
  onOptionClick: (option: any) => void
  params?: any
  checkboxTree: any
  filterCount: string
  wrapperClass?: string
}

const CheckboxWithSearchFilter: React.FC<FilterProps> = ({ ...props }) => {
  // TODO: improve modularity of this function for more scenarios. A mapping enum or something like that for the value rendered
  const formatServerSideSearchResults = (data) => {
    if (props.label === PRODUCTS.CONNECTIONS.NAME) {
      return (
        <div>
          {data.map((item) => (
            <p
              className='hover-cursor ml-3'
              onClick={() => props?.onOptionClick(item)}
              key={item.id}
            >
              {item.retailer?.name}
            </p>
          ))}
        </div>
      )
    }
  }

  return (
    <div className={props.wrapperClass}>
      <Filter
        {...props}
        renderResults={
          <FilterSidebar
            header={
              <SearchBarServerSide
                params={props.params}
                variant={SearchVariant.FIXED_GLOBAL}
                fetchDataCall={getConnections}
                resultsFormatter={formatServerSideSearchResults}
              />
            }
            checkboxTree={props.checkboxTree}
            footer={
              props.checkboxTree?.length
                ? {
                    linkText: FILTERS.TEXT.RESET_ALL,
                    onLinkClick: () => {
                      props.onClear()
                    },
                  }
                : ''
            }
            onChange={(id) => props.onChange(id)}
            variant={FilterSideBarVariant.SECONDARY}
          />
        }
      />
    </div>
  )
}

export default CheckboxWithSearchFilter
