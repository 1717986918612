import { createGlobalStyle } from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const GlobalStyled = createGlobalStyle`
  p {
    ${theme.typography.body1.regular}
    margin: 0;
  }
  a {
    text-decoration: none;
    color: ${theme.color.navigation.active}
  }
  .body1-medium {
    ${theme.typography.body1.medium}
  }
  .body1-regular {
    ${theme.typography.body1.regular}
  }
  .body2-regular {
    ${theme.typography.body2.regular}
  }
  .body2-medium {
    ${theme.typography.body2.medium}
  }
  .h1 {
    ${theme.typography.h1}
  }
  .h2 {
    ${theme.typography.h2}
  }
  .h3 {
    ${theme.typography.h3}
  }
  .h4 {
    ${theme.typography.h4}
  }
  .h5 {
    ${theme.typography.h5}
  }
  .kicker {
    ${theme.typography.kicker}
  }
  .card-header {
    ${theme.typography.h5};
    font-weight: 500;
  }
  .grey-500 {
    color: ${theme.color.grey[500]}
  }
  .grey-600 {
    color: ${theme.color.grey[600]}
  }
  .blue-500 {
    color: ${theme.color.blue[500]}
  }
  .green-700 {
    color: ${theme.color.green[700]}
  }
  .red-700 {
    color: ${theme.color.red[700]}
  }
  .clickableCells: hover {
    cursor: pointer;
    color: ${theme.color.blue[300]};
  }
  .clickableCard: hover {
    cursor: pointer;
    background-color: ${theme.color.blue[50]};
  }
  .grey-400 {
    color: ${theme.color.grey[400]};
  }
  .grey-700 {
    color: ${theme.color.grey[700]};
  }
  .grey-900 {
    color: ${theme.color.grey[900]};
  }
  // Brand/Primary/Charcoal
  .grey-1000 {
    color: ${theme.color.grey[1000]};
  }
  .border-t {
    border-top: 1px solid  ${theme.color.grey[300]};
  }
  .border-b {
    border-bottom: 1px solid  ${theme.color.grey[300]};
  }
  .grey-sm {
    font-size: 0.9em;
    color: ${theme.color.grey[500]};
    font-weight: 400;
  }
  .ui-card-content-style-match {
    color: ${theme.color.grey[700]};
    ${theme.typography.body1.regular}
    margin: 0.5rem;
  }
  .ui-card-content-style-match-no-margin {
    color: ${theme.color.grey[700]};
    ${theme.typography.body1.regular}
  }
  .grey-xs {
    font-size: 0.8em;
    color: ${theme.color.grey[300]};
  }
  .italic {
    font-style: italic;
  }
  .padding-inline-start-20 {
    padding-inline-start: 20px;
  }
  .margin-auto: {
    margin: auto;
  }
  .m-0 {
    margin: 0
  }
  .first-mt-0:first-child {
    margin-top: 0;
  }
  .mt-0 {
    margin-top: 0;
  }
  .mt-1 {
    margin-top: ${theme.space[1]};
  }
  .mt-2 {
    margin-top: ${theme.space[2]};
  }
  .mt-3 {
    margin-top: ${theme.space[3]};
  }
  .mt-4 {
    margin-top: ${theme.space[4]};
  }
  .mt-5 {
    margin-top: ${theme.space[5]};
  }
  .mt-6 {
    margin-top: ${theme.space[6]};
  }
  .last-mb-0:last-child {
    margin-bottom: 0;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: ${theme.space[1]};
  }
  .mb-2 {
    margin-bottom: ${theme.space[2]};
  }
  .mb-3 {
    margin-bottom: ${theme.space[3]};
  }
  .mb-4 {
    margin-bottom: ${theme.space[4]};
  }
  .mb-5 {
    margin-bottom: ${theme.space[5]};
  }
  .mb-6 {
    margin-bottom: ${theme.space[6]};
  }
  .mr-1 {
    margin-right: ${theme.space[1]};
  }
  .mr-2 {
    margin-right: ${theme.space[2]};
  }
  .mr-3 {
    margin-right: ${theme.space[3]};
  }
  .mr-4 {
    margin-right: ${theme.space[4]};
  }
  .mr-5 {
    margin-right: ${theme.space[5]};
  }
  .ml-n1 {
    margin-left: -${theme.space[1]};
  }
  .ml-1 {
    margin-left: ${theme.space[1]};
  }
  .ml-2 {
    margin-left: ${theme.space[2]};
  }
  .ml-3 {
    margin-left: ${theme.space[3]};
  }
  .p-0 {
    padding: 0;
  }
  .p-1 {
    padding: ${theme.space[1]};
  }
  .p-2 {
    padding: ${theme.space[2]};
  }
  .p-3 {
    padding: ${theme.space[3]};
  }
  .p-4 {
    padding: ${theme.space[4]};
  }
  .p-5 {
    padding: ${theme.space[5]};
  }
  .pt-0 {
    padding-top: 0;
  }
  .pt-1 {
    padding-top: ${theme.space[1]};
  }
  .pt-2 {
    padding-top: ${theme.space[2]};
  }
  .pt-3 {
    padding-top: ${theme.space[3]};
  }
  .pt-4 {
    padding-top: ${theme.space[4]};
  }
  .pt-5 {
    padding-top: ${theme.space[5]};
  }
  .pt-6 {
    padding-top: ${theme.space[6]};
  }
  .pb-0 {
    padding-bottom: 0;
  }
  .pb-1 {
    padding-bottom: ${theme.space[1]};
  }
  .pb-2 {
    padding-bottom: ${theme.space[2]};
  }
  .pb-3 {
    padding-bottom: ${theme.space[3]};
  }
  .pb-4 {
    padding-bottom: ${theme.space[4]};
  }
  .pb-5 {
    padding-bottom: ${theme.space[5]};
  }
  .pr-1 {
    padding-right: ${theme.space[1]};
  }
  .pr-2 {
    padding-right: ${theme.space[2]};
  }
  .pr-3 {
    padding-right: ${theme.space[3]};
  }
  .pr-4 {
    padding-right: ${theme.space[4]};
  }
  .pr-5 {
    padding-right: ${theme.space[5]};
  }
  .pl-1 {
    padding-left: ${theme.space[1]};
  }
  .pl-2 {
    padding-left: ${theme.space[2]};
  }
  .pl-3 {
    padding-left: ${theme.space[3]};
  }
  .pl-4 {
    padding-left: ${theme.space[4]};
  }
  .pl-5 {
    padding-left: ${theme.space[5]};
  }
  .v-center {
    display: flex;
    align-items: center;
  }
  .flex-column-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .is-flex {
    display: flex;
  }
  .flex-end {
    display: flex;
    justify-content: end;
  }
  .flex-start {
    display: flex;
    justify-content: flex-start;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .right {
    margin-left: auto;
  }
  .align-start {
    align-items: start;
  }
  .gap-1 {
    gap: ${theme.space[1]};
  }
  .gap-2 {
    gap: ${theme.space[2]};
  }
  .gap-3 {
    gap: ${theme.space[3]};
  }
  .gap-4 {
    gap: ${theme.space[4]};
  }
  .label {
    ${theme.typography.fieldLabel}
  }
  .kicker {
    ${theme.typography.kicker}
  }
  .container {
    padding: ${theme.space[3]} ${theme.space[6]} ${theme.space[4]} ${theme.space[6]};
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
  .small-text {
    ${theme.typography.smallText}
  }
  .hover-cursor:hover {
    cursor: pointer;
  }
  .negative-currency {
    color: ${theme.color.red[600]}
  }
  .width-100 {
    width: 100%;
  }
  .width-50 {
    width: 50%;
  }
  .width-49 {
    width: 49%;
  }
  .width-100px {
    width: 100px;
  }
  .font-l {
    font-size: 1.5em;
  }
  .border-line {
    width: 100%;
    height: 0.0625rem;
    background-color: ${theme.color.grey[200]}
  }
  .card-divider-line {
    width: 100%;
    height: 2px;
    background-color: ${theme.color.grey[200]}
  }
  .textAlignCenter {
    text-align: center
  }
  .cardTitles {
    margin: 0 auto;
  }
  .styledCardHeader {
    .heading {
      ${theme.typography.h5}
      margin: 6px 0;
    }
    .subHeading {
      ${theme.typography.body1.regular}
    }
  }
  .edit-table-row {
    display: flex;
    justify-content: flex-start;
    margin-right: ${theme.space[4]};
    gap: ${theme.space[2]};
  }
  .truncate {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .color-red {
    color: ${theme.color.red[500]};
  }
  .cardHeaderWithProgress {
    width: 100%;
    height: 64px;
    .heading {
      ${theme.typography.h5}
      margin-bottom: ${theme.space[4]};
    }
  }
  .list-style-none {
    list-style: none;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .filterDiv {
    margin: ${theme.space[3]} 0 ${theme.space[4]} 0;
  }
  .cardHeaderWithButton {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    . heading {
      ${theme.typography.h5}
    }
  }
  .divider-4 {
    height: ${theme.space[4]}
  }
  .searchResultListItem {
    padding: 8px 16px;
    .heading {
      color: ${theme.color.grey[1000]};
      ${theme.typography.body1.regular}
    }
    .subHeading {
      color: ${theme.color.grey[600]};
      ${theme.typography.fieldLabel}
    }
    &:hover {
      background: ${theme.color.blue[50]};
    }
  }
  .longTextLine2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .table-action-row{
    display: flex;
    margin-right: ${theme.space[4]};
    gap: ${theme.space[2]};
  }
  .line-separator {
    height: 1px;
    background-color:${theme.color.grey[200]};
    width: 100%;
  }
`
