import { PageHeader as PageHeaderUI } from '@teamfabric/copilot-ui'
import { BadgeProps } from 'atoms/badge/badge'
import { ImageProps } from 'atoms/image/image'
import React from 'react'

export type PageHeaderProps = {
  h1Text: any
  h2Text?: string | JSX.Element
  imageProps?: ImageProps
  badgeProps?: BadgeProps
  ternaryDescription?: string
  loading?: boolean
  tertiaryButtons?: any
  primaryButtons?: any
  progressBarProps?: any
  switchProps?: any
}

const PageHeader: React.FC<PageHeaderProps> = ({ ...props }) => {
  return <PageHeaderUI {...props} />
}

export default PageHeader
