import React, { useState, useEffect } from 'react'
import { GridCol, GridRow, Card } from 'atoms'
import AddressForm from 'molecules/address/form'
import {
  AddressFormDataType,
  AddressFormDefaultValues,
} from 'molecules/address/constants'
import { getLocations } from 'api/locations'
import { useToast } from '@teamfabric/copilot-ui'

type BusinessAddressProps = {
  setFormData: (data: any) => void
  setInitialValues: (data: any) => void
}

const BusinessAddressCard: React.FC<BusinessAddressProps> = ({
  setFormData,
  setInitialValues,
}) => {
  const showToast = useToast()
  const [address, setAddress] = useState<AddressFormDataType>(
    AddressFormDefaultValues
  )
  const [isReady, setIsReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState(null)

  const handleUpdate = (data: any) => {
    setAddress(data)
  }

  useEffect(() => {
    if (!loading && location) {
      setFormData((prevFormData) => ({ ...prevFormData, address }))
      setInitialValues((prevFormData) => ({ ...prevFormData, location }))
    }
  }, [!loading, address, location])

  const loadBillingLocation = async () => {
    try {
      setLoading(true)
      const { data } = await getLocations({ params: { type: 'billing' } })
      setLocation(data?.results[0])
      if (data?.results[0]) {
        setAddress({
          name1: data.results[0].address.name1,
          street1: data.results[0].address.street1,
          street2: data.results[0].address.street2,
          postal_code: data.results[0].address.postal_code,
          city: data.results[0].address.city,
          province: data.results[0].address.province,
          country: data.results[0].address.country,
          phone1: data.results[0].address.phone1,
        })
      }
    } catch (_) {
      showToast({
        label: 'Failed to fetch details.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await loadBillingLocation()
    })()
  }, [])

  return (
    <Card
      headerContent='Business Address'
      showCollapse={false}
      bodyContent={
        <div className='p-0'>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <AddressForm
                initialValues={address}
                handleUpdate={handleUpdate}
                showPhone
                handleIsReady={(value) => setIsReady(value)}
                type='business'
              />
            </GridCol>
          </GridRow>
        </div>
      }
    />
  )
}

export default BusinessAddressCard
