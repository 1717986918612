import { DataProps } from '@teamfabric/copilot-ui'

export const handleSingleRowSelect = (
  e,
  rowDetails: any,
  selected: boolean,
  currentlySelectedItems: string[],
  setterHook: any
) => {
  if (selected) {
    setterHook([rowDetails.id, ...currentlySelectedItems])
  } else {
    const newSelectedItemsIds = currentlySelectedItems.filter(
      (selectedItemId) => selectedItemId !== rowDetails.id
    )
    setterHook(newSelectedItemsIds)
  }
}

export const handleAllRowSelect = (
  e,
  allRowsSelected: boolean,
  setterHook: any,
  tableData: DataProps[]
) => {
  if (!allRowsSelected) {
    setterHook([])
  } else {
    const allItemIds = tableData.map((item) => item.id)
    setterHook(allItemIds)
  }
}

export const getTableCaption = (
  currentPage: number,
  totalRows: number,
  dataName?: string
) => {
  return totalRows
    ? `Showing ${10 * (currentPage - 1) + 1} - ${
        10 * currentPage > totalRows ? totalRows : 10 * currentPage
      } of ${totalRows}${dataName ? ` ${dataName}` : ''}`
    : 'Showing 0 of 0'
}
