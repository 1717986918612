import API, { API_URL } from 'api'

export const getProposals = async ({ params = {} }) => {
  return await API.get(API_URL.GET_PROPOSALS(), { params })
}

export const getProposalDetail = async ({ id, params = {} }) => {
  return await API.get(API_URL.GET_PROPOSAL_DETAIL({ id }), { params })
}

export const getProposalProducts = async ({ id, params = {} }) => {
  return await API.get(API_URL.GET_PROPOSAL_PRODUCTS({ id }), {
    params,
  })
}

export const getProposalVariants = async ({ id, params = {} }) => {
  return await API.get(API_URL.GET_PROPOSAL_VARIANTS({ id }), {
    params,
  })
}

export const removeProposalProducts = async ({ id, body }) => {
  return await API.put(API_URL.REMOVE_PROPOSAL_PRODUCTS({ id }), body)
}

export const removeProposalVariants = async ({ id, body }) => {
  return await API.post(API_URL.GET_PROPOSAL_VARIANTS({ id }), body)
}

export const removeProposalProduct = async ({ id, productId, body }) => {
  return await API.put(API_URL.REMOVE_PROPOSAL_PRODUCT({ id, productId }), body)
}

export const getProposalProductDetail = async ({
  id,
  productId,
  params = {},
}) => {
  return await API.get(API_URL.GET_PROPOSAL_PRODUCT_DETAIL({ id, productId }), {
    params,
  })
}

export const getProposalVariantDetail = async ({
  id,
  variantId,
  params = {},
}) => {
  return await API.get(API_URL.GET_PROPOSAL_VARIANT_DETAIL({ id, variantId }), {
    params,
  })
}

export const getProposalSummary = async ({ params = {} }) => {
  return await API.get(API_URL.PROPOSAL_SUMMARY(), { params })
}

export const getProposalRulesetSummary = async ({ id, params = {} }) => {
  return await API.get(API_URL.PROPOSAL_RULESETS_SUMMARY({ id }), {
    params,
  })
}

export const queueFile = async (tempfileId: number, proposalId: number) => {
  const requestBody: {
    template: { id: number | string }
    direction: string
    data_type: string
    behavior: string
    info: {
      proposal_id: number
      proposal_feed_purpose: string
      proposal_tempfile_id: number
    }
    file: { tempfile: { id: number } }
  } = {
    template: {
      //TODO: get value from env
      id: 2411,
    },
    behavior: 'update',
    direction: 'import',
    data_type: 'proposals',
    info: {
      proposal_id: proposalId,
      proposal_feed_purpose: 'update',
      proposal_tempfile_id: tempfileId,
    },
    file: {
      tempfile: {
        id: tempfileId,
      },
    },
  }

  try {
    const enqueueResponse = await API.post(
      API_URL.POST_FEED_QUEUE_ENQUEUE(),
      requestBody
    )
    if (enqueueResponse?.data?.id) {
      const feedQueueId = enqueueResponse.data.id
      const runResponse = await API.put(
        API_URL.PUT_FEED_QUEUE_RUN({ feedQueueId })
      )
      return runResponse
    } else {
      throw new Error('Enqueue response did not contain an id.')
    }
  } catch (error) {
    console.error('Failed to queue file', error)
    throw error
  }
}

export const getFeedQueue = async ({ id }) => {
  return await API.get(API_URL.GET_FEED_QUEUE({ feedQueueId: id }))
}

export const patchProposal = async ({ id, body }) => {
  return await API.patch(API_URL.PATCH_PROPOSAL({ id }), body)
}

export const getProposalReturnReasons = async () => {
  const params = {
    pagination: 0,
  }
  return await API.get(API_URL.GET_PROPOSAL_RETURN_REASONS(), { params })
}

export const acknowledgeProposal = async ({ id }) => {
  return await API.put(API_URL.ACKNOWLEDGE_PROPOSAL({ id }))
}

export const applyTransformerToProposal = async ({ id, body }) => {
  return await API.post(API_URL.PROPOSAL_APPLY_TRANSFORMER({ id }), body)
}

export const exportProposalToFile = async ({ id, params }) => {
  return await API.get(API_URL.EXPORT_PROPOSAL({ id }), { params })
}

export const exportProposalProducts = async ({
  platformCode,
  platformId,
  body,
}) => {
  return await API.post(
    API_URL.EXPORT_PROPOSAL_PRODUCTS({ platformCode, platformId }),
    body
  )
}

export const exportProposalToFabric = async ({ platformId, body }) => {
  return await API.post(API_URL.EXPORT_PROPOSAL_TO_FABRIC({ platformId }), body)
}

export const getProposalIssues = async ({ proposalId, params = {} }) => {
  return await API.get(API_URL.PROPOSAL_ISSUES({ proposalId }), { params })
}

export const getProposalIssueDetails = async ({ proposalId, issueId }) => {
  return await API.get(API_URL.PROPOSAL_ISSUE_BY_ID({ proposalId, issueId }))
}

export const archiveProposalIssue = async ({ proposalId, issueId }) => {
  return await API.put(API_URL.ARCHIVE_PROPOSAL_ISSUE({ proposalId, issueId }))
}

export const closeProposalIssue = async ({ proposalId, issueId }) => {
  return await API.put(API_URL.CLOSE_PROPOSAL_ISSUE({ proposalId, issueId }))
}

export const createProposalIssue = async ({ proposalId, body }) => {
  return await API.post(API_URL.PROPOSAL_ISSUES({ proposalId }), body)
}

export const patchProposalIssue = async ({ proposalId, issueId, body }) => {
  return await API.patch(
    API_URL.PROPOSAL_ISSUE_BY_ID({ proposalId, issueId }),
    body
  )
}
