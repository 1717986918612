import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Image from 'atoms/image/image'
import Link from 'atoms/link/link'
import { Modal } from 'atoms'
import ToolTip from 'atoms/tooltip/tooltip'
import { getProposalVariantDetail } from 'api/proposals'
import InspectItemModal from 'molecules/modals/item/inspectItemModal'
import InventoryDetailsModal from 'molecules/modals/item/inventoryDetailsModal'
import { capitalize } from 'lodash'
import MoreActionButtonChildren from 'molecules/button/moreDropdownChildren'
import RemoveProposalItemsModal from 'modules/proposals/components/modals/removeProposalItem'
import AddToOpenIssueModal from 'modules/proposals/components/modals/addToOpenIssue'
import CreateIssueModal from 'modules/proposals/components/modals/createIssue'
import { getTenantType } from 'api/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type VariantItemProps = {
  variant: any
  href?: string
  onClick?: () => void
  hideImage?: boolean
  isProduct?: boolean
  showInventoryDetailsModal?: boolean
  showUPC?: boolean
  showVariantDetails?: boolean
  showAdditionalActions?: boolean
  loadProducts?: () => void
  loadProposal?: (quiet: boolean) => void
}

const VariantItem: React.FC<VariantItemProps> = ({
  variant,
  onClick,
  href,
  hideImage,
  isProduct,
  showInventoryDetailsModal,
  showUPC,
  showVariantDetails,
  showAdditionalActions,
  loadProducts,
  loadProposal,
}) => {
  const { id } = useParams()
  const [displayInventoryDetails, setDisplayInventoryDetails] = useState(false)
  const [displayVariantDetails, setDisplayVarianDetails] = useState(false)
  const [loadingVariantDetail, setLoadingVariantDetail] = useState(false)
  const [variantDetails, setVariantDetails] = useState(null)
  const [showRemoveItem, setShowRemoveItem] = useState(false)
  const [showAddToOpenIssue, setShowAddToOpenIssue] = useState(false)
  const [showCreateIssue, setShowCreateIssue] = useState(false)

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const fetchVariantData = async () => {
    setLoadingVariantDetail(true)
    const { data } = await getProposalVariantDetail({
      id: id,
      variantId: variant.id,
      params: { editor: 1 },
    })
    setVariantDetails(data)
    setLoadingVariantDetail(false)
  }

  const label = useMemo(() => {
    if (isProduct) {
      return variant?.parent_name ? variant?.parent_name : '--'
    } else return variant?.name ? variant?.name : '--'
  }, [variant, isProduct])

  const identifierLabel = useMemo(() => {
    if (showUPC) {
      return 'UPC'
    } else if (isProduct) {
      return 'Product SKU'
    } else return 'Supplier SKU'
  }, [variant, isProduct, showUPC])

  const identifier = useMemo(() => {
    if (showUPC) {
      return variant?.upc || 'Not set'
    } else if (isProduct) {
      return variant?.parent_identifier
    } else return variant?.brand_identifier
  }, [variant, isProduct, showUPC])

  const handleOnClick = () => {
    if (showInventoryDetailsModal) {
      setDisplayInventoryDetails(true)
    }
    if (showVariantDetails) {
      setDisplayVarianDetails(true)
      fetchVariantData()
    } else {
      onClick()
    }
  }

  const inspectItemModalHeader = useMemo(() => {
    const name = variant?.name
    let color = variant?.color
    let size = variant?.size
    if (!loadingVariantDetail) {
      if (variantDetails?.color) {
        color = variantDetails?.color
      }
      if (variantDetails?.size) {
        size = variantDetails?.size
      }
    }
    return [name, capitalize(color), capitalize(size)]
      .filter((el) => el?.length > 0)
      .join(', ')
  }, [variant, variantDetails, loadingVariantDetail])

  const { isRetailer } = getTenantType()

  const canRemoveItems = useMemo(() => {
    if (!isRetailer) {
      const allowed = ['draft', 'revised']
      if (allowed.includes(proposalDetails?.status)) {
        return true
      }
    } else {
      const allowed = ['proposed']
      if (allowed.includes(proposalDetails?.status)) {
        return true
      }
    }
    return false
  }, [proposalDetails?.status])

  const canAddToOpenIssue = useMemo(() => {
    if (proposalDetails.issues_summary.open > 0) return true
    return false
  }, [proposalDetails?.issues_summary])

  const MoreActions = [
    canAddToOpenIssue
      ? {
          name: 'Add To Open Issue',
          handleClick: () => setShowAddToOpenIssue(true),
        }
      : null,
    {
      name: 'Create Issue',
      handleClick: () => setShowCreateIssue(true),
    },
    canRemoveItems
      ? {
          name: 'Remove Item',
          handleClick: () => setShowRemoveItem(true),
        }
      : null,
  ].filter((action) => action !== null)

  const inspectItemFooterButtons = [
    showAdditionalActions
      ? {
          text: 'More actions',
          icon: 'ArrowDown',
          iconPosition: 'right',
          variant: 'secondary',
          onClick: () => {},
          popoverProps: {
            children: <MoreActionButtonChildren actions={MoreActions} />,
            placement: 'bottom-start',
          },
        }
      : null,
    {
      dataTestid: '',
      onClick: () => setDisplayVarianDetails(false),
      text: 'Close',
      variant: 'primary',
    },
  ].filter((el) => el !== null)

  return (
    <>
      <div className={!hideImage ? 'v-center gap-2' : ''}>
        {!hideImage && (
          <Image
            alt='variant'
            size='xsmall'
            src={variant?.media[0]?.url}
            aspectRatio='1:1'
          />
        )}
        <ToolTip placement='bottomStart' text={label}>
          <div className='flex-column-start truncate'>
            {onClick ||
            href ||
            showInventoryDetailsModal ||
            showVariantDetails ? (
              <Link
                onClick={handleOnClick}
                label={label}
                variant='primary'
                mode='inline'
                size='medium'
                href={href}
              />
            ) : (
              <span>{label}</span>
            )}
            <span className='body2-regular grey-700 pr-1 pl-1'>
              {identifierLabel} {identifier}
            </span>
          </div>
        </ToolTip>
      </div>
      {/* Inventory Detail Modal */}
      <Modal
        headerText='Inventory Details'
        size='small'
        onClose={() => setDisplayInventoryDetails(false)}
        isVisible={displayInventoryDetails}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setDisplayInventoryDetails(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        onBackdropClick={() => setDisplayInventoryDetails(false)}
      >
        <InventoryDetailsModal variant={variant} />
      </Modal>
      {/* Inspect Variant Details Modal */}
      <Modal
        headerText={inspectItemModalHeader}
        description={
          loadingVariantDetail
            ? 'Loading attributes & ruleset data...'
            : `${identifierLabel} ${identifier}`
        }
        onClose={() => setDisplayVarianDetails(false)}
        isVisible={displayVariantDetails}
        footerButtons={inspectItemFooterButtons}
        dataTestid={'inspect-item-modal'}
        onBackdropClick={() => setDisplayVarianDetails(false)}
        variant={loadingVariantDetail ? 'spinner' : 'normal'}
      >
        <div className='mt-5'>
          <InspectItemModal variant={variantDetails} />
        </div>
      </Modal>
      {/* remove item modal */}
      <RemoveProposalItemsModal
        count={1}
        itemIdArray={[variant.id]}
        showModal={showRemoveItem}
        setShowModal={setShowRemoveItem}
        reload={loadProducts}
      />
      {/* Add to open issue modal */}
      <AddToOpenIssueModal
        count={1}
        itemIdArray={[variant?.proposal_variant?.id]}
        showModal={showAddToOpenIssue}
        setShowModal={setShowAddToOpenIssue}
        loadProposal={loadProposal}
      />
      {/* Create new issue modal */}
      <CreateIssueModal
        count={1}
        itemIdArray={[variant?.proposal_variant?.id]}
        showModal={showCreateIssue}
        setShowModal={setShowCreateIssue}
        isProduct={isProduct}
        loadProposal={loadProposal}
      />
    </>
  )
}

export default VariantItem
