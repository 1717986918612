import { APP_PAGE_PATHS } from './constants'

export enum OrderType {
  AllOrders = 'All Orders',
  Invoices = 'Invoices',
  Cancellations = 'Cancellations',
  Returns = 'Returns',
  TrackingNumbers = 'Tracking Numbers',
  CreditMemos = 'Credit Memos',
}

export enum ProductType {
  AllProducts = 'All Products',
  Offers = 'Offers',
  Proposals = 'Proposals',
  ImportRequests = 'Import Requests',
}

export enum SupplierPageTypes {
  Invite = 'Invite Supplier',
  Invitations = 'Invitations',
  Locations = 'Supplier Locations',
}

const baseBreadcrumb = [
  {
    label: 'Suppliers',
    isDisabled: true,
  },
]

export const breadcrumbs = {
  manageSuppliers: () => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Manage Suppliers',
        isDisabled: true,
      },
    ]
  },
  manageSupplierDetails: ({ supplierID }: { supplierID: string }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Manage Suppliers',
        url: APP_PAGE_PATHS.SUPPLIERS_LIST,
      },
      {
        label: `Edit: ${supplierID}`,
        isDisabled: true,
      },
    ]
  },
  supplierPages: ({ type }: { type: SupplierPageTypes }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Manage Suppliers',
        url: APP_PAGE_PATHS.SUPPLIERS_LIST,
      },
      {
        label: type,
        isDisabled: true,
      },
    ]
  },
  supplierProposals: () => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Proposals',
        isDisabled: true,
      },
    ]
  },
  supplierProposalDetails: ({ proposalID }: { proposalID: string }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Proposals',
        url: APP_PAGE_PATHS.SUPPLIERS_PROPOSALS,
      },
      {
        label: `Proposal# ${proposalID}`,
        isDisabled: true,
      },
    ]
  },
  supplierProposalProductDetails: ({
    proposalID,
    productName,
  }: {
    proposalID: string
    productName: string
  }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Proposals',
        url: APP_PAGE_PATHS.SUPPLIERS_PROPOSALS,
      },
      {
        label: `Details: ${proposalID}`,
        url: APP_PAGE_PATHS.SUPPLIERS_PROPOSALS + `/${proposalID}`,
      },
      {
        label: `Product: ${productName}`,
        isDisabled: true,
      },
    ]
  },
  ordersDashboard: () => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Overview',
        isDisabled: true,
      },
    ]
  },
  ordersList: ({ type }: { type: OrderType }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Orders',
        url: '/suppliers/overview',
      },
      {
        label: type,
        isDisabled: true,
      },
    ]
  },
  order: ({ type }: { type: 'create' | 'details' }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Orders',
        url: '/suppliers/overview',
      },
      {
        label: 'All Orders',
        url: '/suppliers/orders',
      },
      {
        label: 'Orders Details',
        isDisabled: true,
      },
    ]
  },
  products: ({ type }: { type: ProductType }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Products',
        isDisabled: true,
      },
      {
        label: type,
        isDisabled: true,
      },
    ]
  },
  productDetails: ({ productId }: { productId: string }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Products',
        isDisabled: true,
      },
      {
        label: 'All Products',
        url: '/suppliers/products',
      },
      {
        label: `Product ${productId}`,
        isDisabled: true,
      },
    ]
  },
  proposalDetails: () => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Products',
        isDisabled: true,
      },
      {
        label: 'Proposals',
        url: '/suppliers/products/proposals',
      },
      {
        label: 'Proposal Details',
        isDisabled: true,
      },
    ]
  },
  importRequestDetail: () => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Products',
        isDisabled: true,
      },
      {
        label: 'Import Requests',
        url: '/suppliers/products/import-requests',
      },
      {
        label: 'Details',
        isDisabled: true,
      },
    ]
  },
  settingsDashboard: () => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Settings',
        isDisabled: true,
      },
    ]
  },
  settingDetails: ({ page }: { page: string }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Settings',
        url: '/suppliers/settings',
      },
      {
        label: `${page}`,
        isDisabled: true,
      },
    ]
  },
  proposalDepartmentDetails: () => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Settings',
        url: '/suppliers/settings',
      },
      {
        label: 'Proposal Departments',
        url: '/suppliers/settings/proposal-departments',
      },
      {
        label: 'Department Details',
        isDisabled: true,
      },
    ]
  },
  productInventoryTemplate: ({ page }: { page: string }) => {
    return [
      ...baseBreadcrumb,
      {
        label: 'Settings',
        url: '/suppliers/settings',
      },
      {
        label: 'Templates',
        url: '/suppliers/settings/templates',
      },
      {
        label: `${page}`,
        isDisabled: true,
      },
    ]
  },
  rulesets: ({ page, id }: { page: string; id?: string }) => {
    const breadcrumbs = [
      ...baseBreadcrumb,
      {
        label: 'Settings',
        url: '/suppliers/settings',
      },
      {
        label: 'Rulesets',
        url: '/suppliers/settings/rulesets',
      },
      {
        label: page === 'editRuleGroup' ? 'Edit Rule Group' : `${page}`,
        isDisabled: true,
      },
    ]
    if (page === 'editRuleGroup') {
      breadcrumbs.splice(breadcrumbs.length - 1, 0, {
        label: `Ruleset #${id}`,
        url: `/suppliers/settings/rulesets/${id}`,
      })
    }
    return breadcrumbs
  },
}
